import { FC, FormEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import css from './role-form.module.scss';
import { Permission } from '../../../../../../types/api';
import { usePermission } from '../../../../../contexts/permission.context';
import { TextInput } from '../../../../../components/text-input/text-input.component';
import { IFormPermission, roleFormSchema, RoleFormValues } from './role-form.schema';
import { Button } from '../../../../../components/button/button.component';
import { Modal } from '../../../../../components/modal/modal.component';
import { filterChangedFormFields } from '../../../../../../utils/react-hook-form.utils';
import DataTable from 'react-data-table-component';
import { TableColumn } from 'react-data-table-component/dist/src/DataTable/types';
import { Checkbox } from '../../../../../components/checkbox/checkbox.component';

interface IProps {
  mode: 'view' | 'create' | 'edit';
  values?: RoleFormValues;
  onCreate?: (values: RoleFormValues) => void;
  onEdit?: () => void;
  onSave?: (values: Partial<RoleFormValues>) => void;
  onCancel?: () => void;
  onDelete?: () => void;
}

export const RoleFormComponent: FC<IProps> = (props) => {
  const { mode, values, onCreate, onEdit, onSave, onCancel, onDelete } = props;
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const roleForm = useForm<RoleFormValues>({
    mode: 'onChange',
    resolver: yupResolver(roleFormSchema),
    defaultValues: values || roleFormSchema.getDefault(),
    values
  });

  const resourceTitles: Record<string, string> = {
    ['Devices']: t('settings.tiles.roles.page.role_page.table.resource_titles.devices'),
    ['Groups']: t('settings.tiles.roles.page.role_page.table.resource_titles.groups'),
    ['Profiles']: t('settings.tiles.roles.page.role_page.table.resource_titles.profiles'),
    ['Policies']: t('settings.tiles.roles.page.role_page.table.resource_titles.policies'),
    ['Commands']: t('settings.tiles.roles.page.role_page.table.resource_titles.commands'),
    ['Scripts']: t('settings.tiles.roles.page.role_page.table.resource_titles.scripts'),
    ['Packages']: t('settings.tiles.roles.page.role_page.table.resource_titles.packages'),
    ['Administration']: t(
      'settings.tiles.roles.page.role_page.table.resource_titles.administration'
    ),
    ['DeviceUsers']: t('settings.tiles.roles.page.role_page.table.resource_titles.device_users')
  };

  const permissionColumns: TableColumn<IFormPermission>[] = [
    {
      name: t('settings.tiles.roles.page.role_page.table.heading.resource'),
      selector: (row) => resourceTitles[row.resource] || row.resource
    },
    {
      name: t('settings.tiles.roles.page.role_page.table.heading.read'),
      cell: (row, index) => (
        <Checkbox
          className={css.Checkbox}
          key={`Read${row.resource}`}
          register={roleForm.register(`permissions.${index}.read`)}
          disabled={mode === 'view'}
        />
      ),
      center: true
    },
    {
      name: t('settings.tiles.roles.page.role_page.table.heading.edit'),
      cell: (row, index) => (
        <Checkbox
          className={css.Checkbox}
          key={`Edit${row.resource}`}
          register={roleForm.register(`permissions.${index}.edit`)}
          disabled={mode === 'view'}
        />
      ),
      center: true
    }
  ];

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
  };

  const handleCreate = async () => {
    const isValid = await roleForm.trigger();
    if (!isValid) return;

    onCreate?.(roleForm.getValues());
  };

  const handleEdit = () => onEdit?.();

  const handleSave = async () => {
    const isValid = await roleForm.trigger();
    if (!isValid) return;

    const changedValues = filterChangedFormFields(
      roleForm.getValues(),
      roleForm.formState.dirtyFields
    );

    onSave?.(changedValues);
  };

  const handleCancel = async () => {
    await roleForm.reset();
    onCancel?.();
  };

  const handleDeleteModalOpen = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteModalSubmit = () => {
    onDelete?.();
    setIsDeleteModalOpen(false);
  };

  return (
    <form className={css.Root} onSubmit={handleFormSubmit}>
      <fieldset className={css.Fieldset} disabled={!isAllowedTo(Permission.EditAdministration)}>
        <h5 className={css.Title}>
          {mode === 'create'
            ? t('settings.tiles.roles.page.role_page.new_title')
            : values?.title || ''}
        </h5>
        <div className={css.Content}>
          <TextInput
            label={t('settings.tiles.roles.page.role_page.title')}
            required
            errorText={roleForm.formState.errors.title?.message}
            register={roleForm.register('title')}
            disabled={mode === 'view'}
            autoComplete="new-password"
          />
          <DataTable
            columns={permissionColumns}
            data={roleForm.getValues().permissions}
            noDataComponent={t('common.no_records_in_table')}
          />
        </div>
        <div className={css.ActionButtons}>
          {mode === 'create' && (
            <Button className={css.ActionBtn} theme="primary" onClick={handleCreate}>
              {t('settings.tiles.roles.page.role_page.create_btn')}
            </Button>
          )}
          {mode === 'view' && (
            <>
              <Button className={css.ActionBtn} theme="primary" onClick={handleEdit}>
                {t('settings.tiles.roles.page.role_page.edit_btn')}
              </Button>
              <Button className={css.ActionBtn} theme="danger" onClick={handleDeleteModalOpen}>
                {t('settings.tiles.roles.page.role_page.delete_btn')}
              </Button>
            </>
          )}

          {mode === 'edit' && (
            <>
              <Button className={css.ActionBtn} theme="primary" onClick={handleSave}>
                {t('settings.tiles.roles.page.role_page.save_btn')}
              </Button>
              <Button className={css.ActionBtn} theme="danger" onClick={handleCancel}>
                {t('settings.tiles.roles.page.role_page.cancel_btn')}
              </Button>
            </>
          )}
        </div>
      </fieldset>
      <Modal
        title={t('settings.tiles.roles.page.role_page.modal_delete.title')}
        submitButtonName={t('settings.tiles.roles.page.role_page.modal_delete.delete_btn')}
        isOpen={isDeleteModalOpen}
        onRequestClose={handleDeleteModalClose}
        onSubmit={handleDeleteModalSubmit}
      >
        <p>
          {t('settings.tiles.roles.page.role_page.modal_delete.message', {
            title: values?.title || values?.title
          })}
        </p>
      </Modal>
    </form>
  );
};
