import { FC, FormEvent, useEffect, useState } from 'react';
import css from './configuration-profile-form.module.scss';
import { Button } from '../../../components/button/button.component';
import { TabItem, TabNavbar } from '../../../components/tab/tab-navbar/tab-navbar.component';
import { useTranslation } from 'react-i18next';
import { faDownload, faFileCode, faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { TextInput } from '../../../components/text-input/text-input.component';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  ConfigurationProfileFormValues,
  ConfigurationProfileUploadFormValues,
  formSchema
} from './configuration-profile-form.schema';
import { ConfigurationProfileGeneral } from './configuration-profile-payload-general/configuration-profile-payload-general';
import { ScopeForm, ScopeValues } from '../../../components/scope-form/scope-form.component';
import { LogsTab } from './logs-tab/configuration-profile-logs-tab.component';
import { Modal } from '../../../components/modal/modal.component';
import { usePermission } from '../../../contexts/permission.context';
import { Permission } from '../../../../types/api';

export enum ConfigurationProfilePageTabs {
  OPTIONS = 'Options',
  SCOPE = 'Scope',
  LOGS = 'Logs'
}

type ConfigurationProfileFormOptions = 'raw' | 'general';

interface IProps {
  values?: ConfigurationProfileFormValues | ConfigurationProfileUploadFormValues;
  mode: 'view' | 'upload' | 'update' | 'create' | 'edit';
  onUpload?: (values: ConfigurationProfileUploadFormValues) => void;
  onCreate?: (values: ConfigurationProfileFormValues) => void;
  onDelete?: () => void;
  onDownload?: () => void;
  onEditStart?: () => void;
  onEditCancel?: () => void;
  onEditRequest?: (values: ConfigurationProfileFormValues) => void;
}

export const ConfigurationProfileForm: FC<IProps> = (props: IProps) => {
  const {
    values,
    mode,
    onUpload,
    onDelete,
    onDownload,
    onCreate,
    onEditStart,
    onEditCancel,
    onEditRequest
  } = props;
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<string>(
    mode === 'upload' ? ConfigurationProfilePageTabs.SCOPE : ConfigurationProfilePageTabs.OPTIONS
  );
  const [scopeValues, setScopeValues] = useState<ScopeValues>(
    values?.scope || { scoped: true, scopeEntriesInclude: [], scopeEntriesExclude: [] }
  );
  const [selectedOption, setSelectedOption] = useState<ConfigurationProfileFormOptions>('general');
  const form = useForm<ConfigurationProfileFormValues>({
    mode: 'onChange',
    resolver: yupResolver(formSchema),
    defaultValues: values || formSchema.getDefault()
  });

  useEffect(() => {
    form.reset(values);
  }, [values]);

  const payload = values?.imported_payload || JSON.stringify(values?.payload, null, 2);
  const isImported = !!form.watch('imported_payload');

  const handleScopeChange = (input: Partial<ScopeValues>) => {
    const newScope = { ...scopeValues, ...input };
    setScopeValues(newScope);
    form.setValue('scope', newScope);
  };

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
  };
  const handleUpload = async () => {
    onUpload?.({ scope: scopeValues });
  };
  const handleDownload = async () => {
    onDownload?.();
  };

  const handleCreate = async () => {
    await form.trigger();
    const errorKeys = Object.keys(form.formState.errors);
    if (errorKeys.length > 0) return;

    onCreate?.(form.getValues());
  };

  const handleEditStart = () => onEditStart?.();
  const handleEditCancel = () => onEditCancel?.();
  const handleEditRequest = async () => {
    await form.trigger();
    const errorKeys = Object.keys(form.formState.errors);
    if (errorKeys.length > 0) return;
    onEditRequest?.({
      ...form.getValues(),
      scope: scopeValues
    });
  };

  const handleDeleteModalOpen = () => {
    setIsDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };
  const handleDeleteModalSubmit = () => {
    onDelete?.();
    setIsDeleteModalOpen(false);
  };

  const handleChangeTab = (tab: string) => {
    setActiveTab(tab);
  };

  const handleOptionTabChange = (optionTab: ConfigurationProfileFormOptions) => () => {
    setSelectedOption(optionTab);
  };

  useEffect(() => {
    if (!values) return;
    if (values.scope) {
      setScopeValues(values.scope);
    }
    form.reset(values);
  }, [values]);

  const tabs: TabItem[] = [
    {
      key: ConfigurationProfilePageTabs.OPTIONS,
      title: t('profiles.profile_form.options_tab.name')
    },
    { key: ConfigurationProfilePageTabs.SCOPE, title: t('profiles.profile_form.scope_tab.name') },
    { key: ConfigurationProfilePageTabs.LOGS, title: t('profiles.profile_form.logs_tab.name') }
  ];

  const uploadTabs: TabItem[] = [
    { key: ConfigurationProfilePageTabs.SCOPE, title: t('profiles.profile_form.scope_tab.name') }
  ];

  return (
    <form className={css.Root} onSubmit={handleFormSubmit}>
      {mode !== 'upload' && (
        <TabNavbar tabs={tabs} activeTab={activeTab} onChangeTab={handleChangeTab} />
      )}
      {mode === 'upload' && (
        <TabNavbar tabs={uploadTabs} activeTab={activeTab} onChangeTab={handleChangeTab} />
      )}
      {activeTab === ConfigurationProfilePageTabs.OPTIONS && (
        <div className={css.Options}>
          <ul className={css.OptionTabs}>
            <li
              onClick={handleOptionTabChange('general')}
              className={cn(css.OptionTabItem, selectedOption === 'general' && css.Active)}
            >
              <FontAwesomeIcon className={css.OptionTabItemIcon} size="sm" icon={faGear} />
              <span>{t('profiles.profile_form.options_tab.general.name')}</span>
            </li>
            {payload && (
              <li
                onClick={handleOptionTabChange('raw')}
                className={cn(css.OptionTabItem, selectedOption === 'raw' && css.Active)}
              >
                <p className={css.OptionTabText}>
                  <FontAwesomeIcon className={css.OptionTabItemIcon} size="sm" icon={faFileCode} />
                  <span>{t('profiles.profile_form.options_tab.raw_xml')}</span>
                </p>
              </li>
            )}
          </ul>
          <div className={css.OptionForm}>
            {selectedOption === 'general' && (
              <ConfigurationProfileGeneral disabled={isImported || mode === 'view'} form={form} />
            )}
            {selectedOption === 'raw' && payload && (
              <div className={css.RawJson}>
                <h5 className={css.GroupTitle}>{t('profiles.profile_form.options_tab.raw_xml')}</h5>
                <TextInput
                  className={cn(css.Textarea, css.Code)}
                  label="Raw XML payload"
                  value={payload}
                  disabled
                  textarea
                  required
                />
              </div>
            )}
          </div>
        </div>
      )}

      {activeTab === ConfigurationProfilePageTabs.SCOPE && (
        <ScopeForm
          className={css.PolicyScope}
          values={scopeValues}
          disabled={mode === 'view'}
          onChange={handleScopeChange}
        />
      )}
      {activeTab === ConfigurationProfilePageTabs.LOGS && <LogsTab />}
      <div className={css.PolicyActions}>
        {mode === 'create' && (
          <Button theme="primary" type="submit" onClick={handleCreate}>
            {t('profiles.profile_form.create_profile_btn')}
          </Button>
        )}
        {mode === 'upload' && (
          <Button theme="primary" type="submit" onClick={handleUpload}>
            {t('profiles.profile_form.upload_profile_btn')}
          </Button>
        )}
        {mode === 'view' && (
          <>
            <Button theme="primary" className={css.ConfigProfileActionBtn} onClick={handleDownload}>
              <FontAwesomeIcon icon={faDownload} />
            </Button>
            <Button
              isDisabled={!isAllowedTo(Permission.EditProfiles)}
              theme="primary"
              className={css.ConfigProfileActionBtn}
              onClick={handleEditStart}
            >
              {t('profiles.profile_form.edit_profile_btn')}
            </Button>
            <Button
              isDisabled={!isAllowedTo(Permission.EditProfiles)}
              theme="danger"
              className={css.ConfigProfileActionBtn}
              onClick={handleDeleteModalOpen}
            >
              {t('profiles.profile_form.delete_profile_btn')}
            </Button>
          </>
        )}
        {mode === 'edit' && (
          <>
            <Button
              className={css.ConfigProfileActionBtn}
              theme="primary"
              onClick={handleEditRequest}
            >
              {t('profiles.profile_form.edit_btn')}
            </Button>
            <Button
              className={css.ConfigProfileActionBtn}
              theme="danger"
              onClick={handleEditCancel}
            >
              {t('profiles.profile_form.cancel_btn')}
            </Button>
          </>
        )}
      </div>
      <Modal
        title={t('profiles.profile_form.modal_delete.title')}
        submitButtonName={t('profiles.profile_form.modal_delete.delete_btn')}
        isOpen={isDeleteModalOpen}
        onRequestClose={handleDeleteModalClose}
        onSubmit={handleDeleteModalSubmit}
      >
        <p>{t('profiles.profile_form.modal_delete.message', { title: values?.display_name })}</p>
      </Modal>
    </form>
  );
};
