import { FC, ReactNode, useMemo } from 'react';
import axios from 'axios';
import { useAuthContext } from '../../contexts/auth.context';
import { AxiosRequestConfigExtended } from '../../../api/create-request';

interface IProps {
  children?: ReactNode;
}

export const WithAxios: FC<IProps> = ({ children }) => {
  const { authService } = useAuthContext();

  useMemo(() => {
    axios.interceptors.request.use(async (config) => {
      const startsWithHttp = config.url?.search(/^https?:\/\//) === 0;
      if (startsWithHttp) {
        return config;
      }

      const configExtended = config as AxiosRequestConfigExtended;
      const isPublic = configExtended.public;
      if (isPublic) {
        return config;
      }

      if (!authService.isTokenValid()) {
        await authService.refreshToken();
      }
      const accessToken = authService.accessToken();
      if (accessToken && config.headers) {
        config.headers['X-Auth-Token'] = accessToken;
      }
      return config;
    });
  }, []);

  return <>{children}</>;
};
