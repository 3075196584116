import { format } from 'date-fns';
import { ISODateString } from '../types/common';

export function formatDate(date: ISODateString, formatString?: string): string {
  let formattedDate = '';
  try {
    formattedDate = format(new Date(date), formatString || 'dd-MM-yyyy HH:mm');
  } catch {
    formattedDate = date;
  }
  return formattedDate;
}

export function dateSort(dateA: string | number | Date, dateB: string | number | Date) {
  const timestampA = new Date(dateA).getTime();
  const timestampB = new Date(dateB).getTime();

  if (timestampA > timestampB) {
    return 1;
  }

  if (timestampB > timestampA) {
    return -1;
  }

  return 0;
}
