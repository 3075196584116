import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import css from './commands-tab.module.scss';
import {
  faCircle,
  faDownload,
  faGears,
  faKey,
  faLaptop,
  faLock,
  faMagnifyingGlassLocation,
  faRefresh,
  faTrashCan,
  faTriangleExclamation,
  faUnlock
} from '@fortawesome/free-solid-svg-icons';
import { faBluetooth, faBluetoothB } from '@fortawesome/free-brands-svg-icons';
import { FC, FormEvent, useEffect, useState } from 'react';
import {
  CommandDto,
  CommandStatus,
  DeviceFullDto,
  GetCommandsResponseDto,
  Permission
} from '../../../../../../../../../../../types/api';
import {
  clearPasscode,
  disableBluetooth,
  disableLostMode,
  disableRemoteDesktop,
  enableBluetooth,
  enableLostMode,
  enableRemoteDesktop,
  getCommands,
  lockDevice,
  lockMobileDevice,
  performOsUpdateAction,
  renewMdmProfile,
  sendBlankPush,
  setFirmwarePassword,
  setRecoveryLock,
  unrollDevice,
  updateInventory,
  wipeDevice
} from '../../../../../../../../../../../api/device';
import { Modal } from '../../../../../../../../../../components/modal/modal.component';
import {
  TextInput,
  TextInputType
} from '../../../../../../../../../../components/text-input/text-input.component';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  enableLostModeFormSchema,
  EnableLostModeGeneralForm,
  lockDeviceFormSchema,
  LockDeviceGeneralForm,
  lockMobileDeviceFormSchema,
  LockMobileDeviceGeneralForm,
  PendingCommand,
  pendingCommandTitles,
  setFirmwarePasswordFormSchema,
  SetFirmwarePasswordGeneralForm,
  setRecoveryLockFormSchema,
  SetRecoveryLockGeneralForm,
  wipeDeviceFormSchema,
  WipeDeviceGeneralForm
} from './commands-tab.schema';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../../../../../../../../../utils/time.utils';
import { commandRequestTypes } from '../../../../../../../../../../../const/commands.const';
import { Button } from '../../../../../../../../../../components/button/button.component';
import { getLocalizedErrorString } from '../../../../../../../../../../../utils/localize-error';
import { usePermission } from '../../../../../../../../../../contexts/permission.context';
import useDeviceSection, {
  DeviceType
} from '../../../../../../../../../../contexts/device-section.context';
import { BlankPage } from '../../../../../../../../../../components/blank-page/blank-page.component';

interface IProps {
  device?: DeviceFullDto;
}

export const CommandsTab: FC<IProps> = (props: IProps) => {
  const { device } = props;
  const [isCommandModalLoading, setIsCommandModalLoading] = useState(false);
  const [isCommandModalOpen, setIsCommandModalOpen] = useState(false);
  const [commandModalError, setCommandModalError] = useState('');
  const [pendingCommand, setPendingCommand] = useState<PendingCommand>();
  const [pendingCommandsList, setPendingCommandsList] = useState<CommandDto[]>([]);
  const [pendingCommandsNumber, setPendingCommandsNumber] = useState<number>(0);
  const [failedCommandsList, setFailedCommandsList] = useState<CommandDto[]>([]);
  const [failedCommandsNumber, setFailedCommandsNumber] = useState<number>(0);
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const { deviceType } = useDeviceSection();

  useEffect(() => {
    if (device) {
      getCommands(device?.id, { status: CommandStatus.Pending }).then(
        (req: GetCommandsResponseDto) => {
          setPendingCommandsList(req.commands);
          setPendingCommandsNumber(req.count);
        }
      );
      getCommands(device?.id, { status: CommandStatus.Failed }).then(
        (req: GetCommandsResponseDto) => {
          setFailedCommandsList(req.commands);
          setFailedCommandsNumber(req.count);
        }
      );
    }
  }, []);

  const lockDeviceForm = useForm<LockDeviceGeneralForm>({
    mode: 'onChange',
    resolver: yupResolver(lockDeviceFormSchema),
    defaultValues: lockDeviceFormSchema.getDefault()
  });
  const lockRegister = lockDeviceForm.register;
  const lockFormState = lockDeviceForm.formState;

  const lockMobileDeviceForm = useForm<LockMobileDeviceGeneralForm>({
    mode: 'onChange',
    resolver: yupResolver(lockMobileDeviceFormSchema),
    defaultValues: lockMobileDeviceFormSchema.getDefault()
  });
  const lockMobileRegister = lockMobileDeviceForm.register;

  const wipeDeviceForm = useForm<WipeDeviceGeneralForm>({
    mode: 'onChange',
    resolver: yupResolver(wipeDeviceFormSchema),
    defaultValues: wipeDeviceFormSchema.getDefault()
  });
  const wipeRegister = wipeDeviceForm.register;
  const wipeFormState = wipeDeviceForm.formState;

  const setFirmwarePasswordForm = useForm<SetFirmwarePasswordGeneralForm>({
    mode: 'onChange',
    resolver: yupResolver(setFirmwarePasswordFormSchema),
    defaultValues: setFirmwarePasswordFormSchema.getDefault()
  });
  const setFirmwarePasswordRegister = setFirmwarePasswordForm.register;
  const setFirmwarePasswordFormState = setFirmwarePasswordForm.formState;

  const setRecoveryLockForm = useForm<SetRecoveryLockGeneralForm>({
    mode: 'onChange',
    resolver: yupResolver(setRecoveryLockFormSchema),
    defaultValues: setRecoveryLockFormSchema.getDefault()
  });
  const SetRecoveryLockRegister = setRecoveryLockForm.register;
  const setRecoveryLockFormState = setRecoveryLockForm.formState;

  const enableLostModeForm = useForm<EnableLostModeGeneralForm>({
    mode: 'onChange',
    resolver: yupResolver(enableLostModeFormSchema),
    defaultValues: enableLostModeFormSchema.getDefault()
  });
  const EnableLostModeRegister = enableLostModeForm.register;
  const enableLostModeFormState = enableLostModeForm.formState;

  const handleLockClick = () => {
    setPendingCommand('LockDevice');
    setIsCommandModalOpen(true);
  };
  const handleWipeClick = () => {
    setPendingCommand('EraseDevice');
    setIsCommandModalOpen(true);
  };
  const handleRenewMdmProfileClick = () => {
    setPendingCommand('RenewMDMProfile');
    setIsCommandModalOpen(true);
  };
  const handleRemoveMdmProfileClick = () => {
    setPendingCommand('RemoveMDMProfile');
    setIsCommandModalOpen(true);
  };
  const handleEnableBluetoothClick = () => {
    setPendingCommand('EnableBluetooth');
    setIsCommandModalOpen(true);
  };
  const handleDisableBluetoothClick = () => {
    setPendingCommand('DisableBluetooth');
    setIsCommandModalOpen(true);
  };
  const handleDownloadUpdatesClick = () => {
    setPendingCommand('DownloadUpdates');
    setIsCommandModalOpen(true);
  };
  const handleInstallUpdatesClick = () => {
    setPendingCommand('InstallUpdates');
    setIsCommandModalOpen(true);
  };
  const handleEnableRemoteDesktopClick = () => {
    setPendingCommand('EnableRemoteDesktop');
    setIsCommandModalOpen(true);
  };
  const handleDisableRemoteDesktopClick = () => {
    setPendingCommand('DisableRemoteDesktop');
    setIsCommandModalOpen(true);
  };

  const handleBlankPushClick = () => {
    setPendingCommand('BlankPush');
    setIsCommandModalOpen(true);
  };

  const handleSetFirmwarePasswordClick = () => {
    setPendingCommand('SetFirmwarePassword');
    setIsCommandModalOpen(true);
  };

  const handleSetRecoveryLockClick = () => {
    setPendingCommand('SetRecoveryLock');
    setIsCommandModalOpen(true);
  };
  const handleUpdateInventoryClick = () => {
    setPendingCommand('UpdateInventory');
    setIsCommandModalOpen(true);
  };
  const handleClearPasscodeClick = () => {
    setPendingCommand('ClearPasscode');
    setIsCommandModalOpen(true);
  };
  const handleEnableLostMode = () => {
    setPendingCommand('EnableLostMode');
    setIsCommandModalOpen(true);
  };
  const handleDisableLostMode = () => {
    setPendingCommand('DisableLostMode');
    setIsCommandModalOpen(true);
  };

  const handleModalClose = () => {
    setIsCommandModalOpen(false);
    setCommandModalError('');
    setIsCommandModalLoading(false);
    lockDeviceForm.reset();
    wipeDeviceForm.reset();
    setRecoveryLockForm.reset();
    setFirmwarePasswordForm.reset();
  };
  const handleModalSubmit = async () => {
    if (isCommandModalLoading) return;

    try {
      switch (pendingCommand) {
        case 'LockDevice': {
          if (deviceType === DeviceType.COMPUTERS) {
            await lockDeviceForm.trigger();
            const errorKeys = Object.keys(lockDeviceForm.formState.errors);
            if (errorKeys.length > 0) return;
            if (device) {
              setIsCommandModalLoading(true);
              await lockDevice(device.id, lockDeviceForm.getValues());
            }
            lockDeviceForm.reset();
          } else {
            await lockMobileDeviceForm.trigger();
            const errorKeys = Object.keys(lockMobileDeviceForm.formState.errors);
            if (errorKeys.length > 0) return;
            if (device) {
              setIsCommandModalLoading(true);
              await lockMobileDevice(device.id, lockMobileDeviceForm.getValues());
            }
            lockMobileDeviceForm.reset();
          }

          break;
        }
        case 'RemoveMDMProfile': {
          if (device) {
            setIsCommandModalLoading(true);
            await unrollDevice(device.id);
          }
          break;
        }
        case 'RenewMDMProfile': {
          if (device) {
            setIsCommandModalLoading(true);
            await renewMdmProfile(device.id);
          }
          break;
        }
        case 'EraseDevice': {
          if (device) {
            setIsCommandModalLoading(true);
            if (deviceType === DeviceType.COMPUTERS) {
              const formValues = wipeDeviceForm.getValues();
              if (formValues.pin) await wipeDevice(device.id, wipeDeviceForm.getValues());
              else {
                setCommandModalError(
                  t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.modals.wipe.errors.pin_required'
                  )
                );
                setIsCommandModalLoading(false);
                return;
              }
            }
            if (deviceType === DeviceType.MOBILE) await wipeDevice(device.id);
          }
          break;
        }
        case 'EnableBluetooth': {
          if (device) {
            setIsCommandModalLoading(true);
            await enableBluetooth(device.id);
          }
          break;
        }
        case 'DisableBluetooth': {
          if (device) {
            setIsCommandModalLoading(true);
            await disableBluetooth(device.id);
          }
          break;
        }
        case 'BlankPush': {
          if (device) {
            setIsCommandModalLoading(true);
            await sendBlankPush(device.id);
          }
          break;
        }
        case 'InstallUpdates': {
          if (device) {
            setIsCommandModalLoading(true);
            await performOsUpdateAction(device.id, {
              install_action: 'InstallASAP',
              product_key: ''
            });
          }
          break;
        }
        case 'DownloadUpdates': {
          if (device) {
            setIsCommandModalLoading(true);
            await performOsUpdateAction(device.id, {
              install_action: 'DownloadOnly',
              product_key: ''
            });
          }
          break;
        }
        case 'EnableRemoteDesktop': {
          if (device) {
            setIsCommandModalLoading(true);
            await enableRemoteDesktop(device.id);
          }
          break;
        }
        case 'DisableRemoteDesktop': {
          if (device) {
            setIsCommandModalLoading(true);
            await disableRemoteDesktop(device.id);
          }
          break;
        }
        case 'SetFirmwarePassword': {
          await setFirmwarePasswordForm.trigger();
          const errorKeys = Object.keys(setFirmwarePasswordForm.formState.errors);
          if (errorKeys.length > 0) return;
          if (device) {
            setIsCommandModalLoading(true);
            const result = await setFirmwarePassword(
              device.id,
              setFirmwarePasswordForm.getValues()
            );
            if (result.error) {
              setIsCommandModalLoading(false);
              setCommandModalError(result.error);
              return;
            }
          }
          setFirmwarePasswordForm.reset();
          break;
        }
        case 'SetRecoveryLock': {
          await setRecoveryLockForm.trigger();
          const errorKeys = Object.keys(setRecoveryLockForm.formState.errors);
          if (errorKeys.length > 0) return;
          if (device) {
            setIsCommandModalLoading(true);
            const result = await setRecoveryLock(device.id, setRecoveryLockForm.getValues());
            if (result.error) {
              setIsCommandModalLoading(false);
              setCommandModalError(result.error);
              return;
            }
          }
          setRecoveryLockForm.reset();
          break;
        }
        case 'UpdateInventory': {
          if (device) {
            setIsCommandModalLoading(true);
            await updateInventory(device.id);
          }
          break;
        }
        case 'ClearPasscode': {
          if (device) {
            setIsCommandModalLoading(true);
            const response = await clearPasscode(device.id);
            if (response.error) {
              setCommandModalError(response.error);
              setIsCommandModalLoading(false);
              return;
            }
          }
          break;
        }
        case 'EnableLostMode': {
          await enableLostModeForm.trigger();
          setCommandModalError('');
          const errorKeys = Object.keys(enableLostModeForm.formState.errors);
          if (errorKeys.length > 0) return;
          const formValues = enableLostModeForm.getValues();
          if (
            (!formValues.message || formValues.message === '') &&
            (!formValues.phone_number || formValues.phone_number === '')
          ) {
            setIsCommandModalLoading(false);
            setCommandModalError(
              t(
                'inventory_mobile.device_page.management_tab.commands_tab.commands_section.modals.enable_lost_mode.errors.message_or_phone_number_is_required'
              )
            );
            return;
          }
          if (device) {
            setIsCommandModalLoading(true);
            const result = await enableLostMode(device.id, enableLostModeForm.getValues());
            if (result.error) {
              setIsCommandModalLoading(false);
              setCommandModalError(result.error);
              return;
            }
          }
          setRecoveryLockForm.reset();
          break;
        }
        case 'DisableLostMode': {
          if (device) {
            setIsCommandModalLoading(true);
            await disableLostMode(device.id);
          }
          break;
        }
      }
      setIsCommandModalOpen(false);
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      setCommandModalError(localizedErrorString);
    }
    setIsCommandModalLoading(false);
  };

  const randomizePassword = () => {
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const symbols = '~`! @#$%^&*()_-+={[}]|:;"\'<,>.?/';
    const passwordLength = 11;
    let randomPassword = '';
    for (let i = 0, n = charset.length; i < passwordLength; ++i) {
      randomPassword += charset.charAt(Math.floor(Math.random() * n));
    }
    randomPassword += symbols.charAt(Math.floor(Math.random() * symbols.length));
    return randomPassword;
  };

  const handleClickRandomizeFirmwarePassword = () => {
    const randomPassword = randomizePassword();
    setFirmwarePasswordForm.setValue('new_password', randomPassword);
  };

  const handleClickRandomizeRecoveryLock = () => {
    const randomPassword = randomizePassword();
    setRecoveryLockForm.setValue('new_password', randomPassword);
  };

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
  };

  return device?.mdm_enrollment_status ? (
    <>
      <form onSubmit={handleFormSubmit}>
        <fieldset className={css.Root} disabled={!isAllowedTo(Permission.EditCommands)}>
          <div className="management-tab-content" />
          <h6>{t('inventory.device_page.management_tab.commands_tab.commands_section.name')}</h6>

          {deviceType === DeviceType.COMPUTERS ? (
            <div className={`container ${css.ManagementCommandsGrid}`}>
              <div className="row">
                <button
                  className={`col-sm ${css.CommandBtn}`}
                  onClick={handleLockClick}
                  title={t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.commands.lock'
                  )}
                >
                  <FontAwesomeIcon icon={faLock} />
                  {t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.commands.lock'
                  )}
                </button>
                <button
                  className={`col-sm ${css.CommandBtn}`}
                  onClick={handleRenewMdmProfileClick}
                  title={t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.commands.renew_mdm_profile'
                  )}
                >
                  <FontAwesomeIcon icon={faGears} />
                  {t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.commands.renew_mdm_profile'
                  )}
                </button>
                <button
                  className={`col-sm ${css.CommandBtn}`}
                  onClick={handleRemoveMdmProfileClick}
                  title={t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.commands.remove_mdm_profile'
                  )}
                >
                  <FontAwesomeIcon icon={faTriangleExclamation} />
                  {t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.commands.remove_mdm_profile'
                  )}
                </button>
              </div>
              <div className="row">
                <button
                  className={`col-sm ${css.CommandBtn}`}
                  onClick={handleWipeClick}
                  title={t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.commands.wipe'
                  )}
                >
                  <FontAwesomeIcon icon={faTrashCan} />
                  {t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.commands.wipe'
                  )}
                </button>
                <button
                  className={`col-sm ${css.CommandBtn}`}
                  onClick={handleInstallUpdatesClick}
                  title={t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.commands.install_updates'
                  )}
                >
                  <FontAwesomeIcon icon={faDownload} />
                  {t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.commands.install_updates'
                  )}
                </button>
                <button
                  className={`col-sm ${css.CommandBtn}`}
                  onClick={handleDownloadUpdatesClick}
                  title={t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.commands.download_updates'
                  )}
                >
                  <FontAwesomeIcon icon={faDownload} />
                  {t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.commands.download_updates'
                  )}
                </button>
              </div>
              <div className="row">
                <button
                  className={`col-sm ${css.CommandBtn}`}
                  onClick={handleEnableBluetoothClick}
                  title={t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.commands.enable_bluetooth'
                  )}
                >
                  <FontAwesomeIcon icon={faBluetooth} />
                  {t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.commands.enable_bluetooth'
                  )}
                </button>
                <button
                  className={`col-sm ${css.CommandBtn}`}
                  onClick={handleEnableRemoteDesktopClick}
                  title={t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.commands.enable_ard'
                  )}
                >
                  <FontAwesomeIcon icon={faLaptop} />
                  {t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.commands.enable_ard'
                  )}
                </button>
                <button
                  className={`col-sm ${css.CommandBtn}`}
                  onClick={handleBlankPushClick}
                  title={t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.commands.send_blank_push'
                  )}
                >
                  <FontAwesomeIcon icon={faCircle} />
                  {t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.commands.send_blank_push'
                  )}
                </button>
              </div>
              <div className="row">
                <button
                  className={`col-sm ${css.CommandBtn}`}
                  onClick={handleDisableBluetoothClick}
                  title={t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.commands.disable_bluetooth'
                  )}
                >
                  <FontAwesomeIcon icon={faBluetoothB} />
                  {t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.commands.disable_bluetooth'
                  )}
                </button>
                <button
                  className={`col-sm ${css.CommandBtn}`}
                  onClick={handleDisableRemoteDesktopClick}
                  title={t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.commands.disable_ard'
                  )}
                >
                  <FontAwesomeIcon icon={faLaptop} />
                  {t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.commands.disable_ard'
                  )}
                </button>
                {device?.device_information?.is_apple_silicon ? (
                  <button
                    className={`col-sm ${css.CommandBtn}`}
                    onClick={handleSetRecoveryLockClick}
                    title={t(
                      'inventory.device_page.management_tab.commands_tab.commands_section.commands.set_recovery_lock'
                    )}
                  >
                    <FontAwesomeIcon icon={faKey} />
                    {t(
                      'inventory.device_page.management_tab.commands_tab.commands_section.commands.set_recovery_lock'
                    )}
                  </button>
                ) : (
                  <button
                    className={`col-sm ${css.CommandBtn}`}
                    onClick={handleSetFirmwarePasswordClick}
                    title={t(
                      'inventory.device_page.management_tab.commands_tab.commands_section.commands.set_firmware_password'
                    )}
                  >
                    <FontAwesomeIcon icon={faKey} />
                    {t(
                      'inventory.device_page.management_tab.commands_tab.commands_section.commands.set_firmware_password'
                    )}
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div className={`container ${css.ManagementCommandsGrid}`}>
              <div className="row">
                <button
                  className={`col-sm ${css.CommandBtn}`}
                  onClick={handleLockClick}
                  title={t(
                    'inventory_mobile.device_page.management_tab.commands_tab.commands_section.commands.lock'
                  )}
                >
                  <FontAwesomeIcon icon={faLock} />
                  {t(
                    'inventory_mobile.device_page.management_tab.commands_tab.commands_section.commands.lock'
                  )}
                </button>
                <button
                  className={`col-sm ${css.CommandBtn}`}
                  onClick={handleRenewMdmProfileClick}
                  title={t(
                    'inventory_mobile.device_page.management_tab.commands_tab.commands_section.commands.renew_mdm_profile'
                  )}
                >
                  <FontAwesomeIcon icon={faGears} />
                  {t(
                    'inventory_mobile.device_page.management_tab.commands_tab.commands_section.commands.renew_mdm_profile'
                  )}
                </button>
                <button
                  className={`col-sm ${css.CommandBtn}`}
                  onClick={handleRemoveMdmProfileClick}
                  title={t(
                    'inventory_mobile.device_page.management_tab.commands_tab.commands_section.commands.unmanage_device'
                  )}
                >
                  <FontAwesomeIcon icon={faTriangleExclamation} />
                  {t(
                    'inventory_mobile.device_page.management_tab.commands_tab.commands_section.commands.unmanage_device'
                  )}
                </button>
              </div>
              <div className="row">
                <button
                  className={`col-sm ${css.CommandBtn}`}
                  onClick={handleWipeClick}
                  title={t(
                    'inventory_mobile.device_page.management_tab.commands_tab.commands_section.commands.wipe'
                  )}
                >
                  <FontAwesomeIcon icon={faTrashCan} />
                  {t(
                    'inventory_mobile.device_page.management_tab.commands_tab.commands_section.commands.wipe'
                  )}
                </button>
                <button
                  className={`col-sm ${css.CommandBtn}`}
                  onClick={handleUpdateInventoryClick}
                  title={t(
                    'inventory_mobile.device_page.management_tab.commands_tab.commands_section.commands.update_inventory'
                  )}
                >
                  <FontAwesomeIcon icon={faRefresh} />
                  {t(
                    'inventory_mobile.device_page.management_tab.commands_tab.commands_section.commands.update_inventory'
                  )}
                </button>
                <button
                  className={`col-sm ${css.CommandBtn}`}
                  onClick={handleClearPasscodeClick}
                  title={t(
                    'inventory_mobile.device_page.management_tab.commands_tab.commands_section.commands.clear_passcode'
                  )}
                >
                  <FontAwesomeIcon icon={faUnlock} />
                  {t(
                    'inventory_mobile.device_page.management_tab.commands_tab.commands_section.commands.clear_passcode'
                  )}
                </button>
              </div>
              <div className="row">
                <button
                  className={`col-sm ${css.CommandBtn}`}
                  onClick={handleBlankPushClick}
                  title={t(
                    'inventory_mobile.device_page.management_tab.commands_tab.commands_section.commands.send_blank_push'
                  )}
                >
                  <FontAwesomeIcon icon={faCircle} />
                  {t(
                    'inventory_mobile.device_page.management_tab.commands_tab.commands_section.commands.send_blank_push'
                  )}
                </button>
                <button
                  className={`col-sm ${css.CommandBtn}`}
                  onClick={handleEnableLostMode}
                  title={t(
                    'inventory_mobile.device_page.management_tab.commands_tab.commands_section.commands.enable_lost_mode'
                  )}
                >
                  <FontAwesomeIcon icon={faMagnifyingGlassLocation} />
                  {t(
                    'inventory_mobile.device_page.management_tab.commands_tab.commands_section.commands.enable_lost_mode'
                  )}
                </button>
                <button
                  className={`col-sm ${css.CommandBtn}`}
                  onClick={handleDisableLostMode}
                  title={t(
                    'inventory_mobile.device_page.management_tab.commands_tab.commands_section.commands.disable_lost_mode'
                  )}
                >
                  <FontAwesomeIcon icon={faMagnifyingGlassLocation} />
                  {t(
                    'inventory_mobile.device_page.management_tab.commands_tab.commands_section.commands.disable_lost_mode'
                  )}
                </button>
              </div>
            </div>
          )}
          <h6>
            {t('inventory.device_page.management_tab.commands_tab.pending_commands_section.name')}
          </h6>
          <table className={css.CommandsTabTable}>
            <thead>
              <tr>
                <th>
                  {t(
                    'inventory.device_page.management_tab.commands_tab.pending_commands_section.table.heading.name'
                  )}
                </th>
                <th>
                  {t(
                    'inventory.device_page.management_tab.commands_tab.pending_commands_section.table.heading.issued'
                  )}
                </th>
                <th>
                  {t(
                    'inventory.device_page.management_tab.commands_tab.pending_commands_section.table.heading.last_push'
                  )}
                </th>
              </tr>
            </thead>
            {pendingCommandsNumber ? (
              <tbody>
                {pendingCommandsList.map((command, key) => {
                  return (
                    <tr key={key}>
                      <td>{commandRequestTypes[command.command_type]}</td>
                      <td>{formatDate(command.created_at)}</td>
                      <td>{formatDate(command.updated_at)}</td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={3}>
                    {t(
                      'inventory.device_page.management_tab.commands_tab.pending_commands_section.table.no_pending_commands'
                    )}
                  </td>
                </tr>
              </tbody>
            )}
          </table>
          <h6>
            {t('inventory.device_page.management_tab.commands_tab.failed_commands_section.name')}
          </h6>
          <table className={css.CommandsTabTable}>
            <thead>
              <tr>
                <th>
                  {t(
                    'inventory.device_page.management_tab.commands_tab.failed_commands_section.table.heading.name'
                  )}
                </th>
                <th>
                  {t(
                    'inventory.device_page.management_tab.commands_tab.failed_commands_section.table.heading.issued'
                  )}
                </th>
                <th>
                  {t(
                    'inventory.device_page.management_tab.commands_tab.failed_commands_section.table.heading.last_push'
                  )}
                </th>
              </tr>
            </thead>
            {failedCommandsNumber ? (
              <tbody>
                {failedCommandsList.map((command, key) => {
                  return (
                    <tr key={key}>
                      <td>{commandRequestTypes[command.command_type]}</td>
                      <td>{formatDate(command.created_at)}</td>
                      <td>{formatDate(command.updated_at)}</td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={2}>
                    {t(
                      'inventory.device_page.management_tab.commands_tab.failed_commands_section.table.no_failed_commands'
                    )}
                  </td>
                </tr>
              </tbody>
            )}
          </table>
          <Modal
            isOpen={isCommandModalOpen}
            onRequestClose={handleModalClose}
            onSubmit={handleModalSubmit}
            title={pendingCommand ? pendingCommandTitles[pendingCommand] : ''}
            submitButtonName={t(
              'inventory.device_page.management_tab.commands_tab.commands_section.modals.send_command_btn'
            )}
            isLoading={isCommandModalLoading}
            error={commandModalError}
          >
            {pendingCommand === 'RemoveMDMProfile' && (
              <div className={css.ModalText}>
                <p>
                  {t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.modals.remove_mdm_profile.message'
                  )}
                </p>
              </div>
            )}
            {pendingCommand === 'RenewMDMProfile' && (
              <div className={css.ModalText}>
                <p>
                  {t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.modals.renew_mdm_profile.message'
                  )}
                </p>
              </div>
            )}
            {pendingCommand === 'EnableBluetooth' && (
              <div className={css.ModalText}>
                <p>
                  {t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.modals.enable_bluetooth.message'
                  )}
                </p>
              </div>
            )}
            {pendingCommand === 'DisableBluetooth' && (
              <div className={css.ModalText}>
                <p>
                  {t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.modals.disable_bluetooth.message'
                  )}
                </p>
              </div>
            )}
            {pendingCommand === 'BlankPush' && (
              <div className={css.ModalText}>
                <p>
                  {t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.modals.send_blank_push.message'
                  )}
                </p>
              </div>
            )}
            {pendingCommand === 'LockDevice' && deviceType === DeviceType.COMPUTERS && (
              <form className={css.CommandForm}>
                <TextInput
                  label={t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.modals.lock.field_titles.message'
                  )}
                  required
                  register={lockRegister('message')}
                  errorText={lockFormState.errors.message?.message}
                  insideModal={true}
                />
                <TextInput
                  label={t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.modals.lock.field_titles.pin'
                  )}
                  type={TextInputType.NUMBER_STRING}
                  required
                  register={lockRegister('pin')}
                  errorText={lockFormState.errors.pin?.message}
                  insideModal={true}
                />
                <TextInput
                  label={t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.modals.lock.field_titles.phone_number'
                  )}
                  register={lockRegister('phone_number')}
                  insideModal={true}
                />
              </form>
            )}
            {pendingCommand === 'LockDevice' && deviceType === DeviceType.MOBILE && (
              <form className={css.CommandForm}>
                <TextInput
                  label={t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.modals.lock.field_titles.message'
                  )}
                  register={lockMobileRegister('message')}
                  insideModal={true}
                />
                <TextInput
                  label={t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.modals.lock.field_titles.phone_number'
                  )}
                  register={lockMobileRegister('phone_number')}
                  insideModal={true}
                />
              </form>
            )}
            {pendingCommand === 'EraseDevice' && deviceType === DeviceType.COMPUTERS && (
              <form className={css.CommandForm}>
                <TextInput
                  label={t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.modals.wipe.field_titles.pin'
                  )}
                  type={TextInputType.NUMBER_STRING}
                  required
                  register={wipeRegister('pin')}
                  errorText={wipeFormState.errors.pin?.message}
                  insideModal={true}
                />
              </form>
            )}
            {pendingCommand === 'EraseDevice' && deviceType === DeviceType.MOBILE && (
              <form className={css.CommandForm}>
                <p>
                  {t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.modals.wipe_ios.message'
                  )}
                </p>
              </form>
            )}
            {pendingCommand === 'DownloadUpdates' && (
              <div className={css.ModalText}>
                <p>
                  {t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.modals.download_updates.message'
                  )}
                </p>
              </div>
            )}
            {pendingCommand === 'InstallUpdates' && (
              <div className={css.ModalText}>
                <p>
                  {t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.modals.install_updates.message'
                  )}
                </p>
              </div>
            )}
            {pendingCommand === 'EnableRemoteDesktop' && (
              <div className={css.ModalText}>
                <p>
                  {t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.modals.enable_ard.message'
                  )}
                </p>
              </div>
            )}
            {pendingCommand === 'DisableRemoteDesktop' && (
              <div className={css.ModalText}>
                <p>
                  {t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.modals.disable_ard.message'
                  )}
                </p>
              </div>
            )}
            {pendingCommand === 'SetFirmwarePassword' && (
              <form className={css.CommandForm}>
                <TextInput
                  label={t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.modals.set_firmware_password.field_titles.current_password'
                  )}
                  type={TextInputType.TEXT}
                  register={setFirmwarePasswordRegister('current_password')}
                />
                <TextInput
                  label={t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.modals.set_firmware_password.field_titles.new_password'
                  )}
                  type={TextInputType.TEXT}
                  required
                  register={setFirmwarePasswordRegister('new_password')}
                  errorText={setFirmwarePasswordFormState.errors.new_password?.message}
                />
                <Button theme="primary" onClick={handleClickRandomizeFirmwarePassword}>
                  {t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.modals.set_recovery_lock.randomize_btn'
                  )}
                </Button>
              </form>
            )}
            {pendingCommand === 'SetRecoveryLock' && (
              <form className={css.CommandForm}>
                <TextInput
                  label={t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.modals.set_recovery_lock.field_titles.current_password'
                  )}
                  type={TextInputType.TEXT}
                  register={SetRecoveryLockRegister('current_password')}
                />
                <TextInput
                  label={t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.modals.set_recovery_lock.field_titles.new_password'
                  )}
                  type={TextInputType.TEXT}
                  required
                  register={SetRecoveryLockRegister('new_password')}
                  errorText={setRecoveryLockFormState.errors.new_password?.message}
                />
                <Button theme="primary" onClick={handleClickRandomizeRecoveryLock}>
                  {t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.modals.set_recovery_lock.randomize_btn'
                  )}
                </Button>
              </form>
            )}
            {pendingCommand === 'UpdateInventory' && (
              <div className={css.ModalText}>
                <p>
                  {t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.modals.update_inventory.message'
                  )}
                </p>
              </div>
            )}
            {pendingCommand === 'ClearPasscode' && (
              <div className={css.ModalText}>
                <p>
                  {t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.modals.clear_passcode.message'
                  )}
                </p>
              </div>
            )}
            {pendingCommand === 'EnableLostMode' && (
              <form className={css.CommandForm}>
                <TextInput
                  label={t(
                    'inventory_mobile.device_page.management_tab.commands_tab.commands_section.modals.enable_lost_mode.field_titles.message'
                  )}
                  type={TextInputType.TEXT}
                  register={EnableLostModeRegister('message')}
                  errorText={enableLostModeFormState.errors.message?.message}
                />
                <TextInput
                  label={t(
                    'inventory_mobile.device_page.management_tab.commands_tab.commands_section.modals.enable_lost_mode.field_titles.phone_number'
                  )}
                  type={TextInputType.TEXT}
                  register={EnableLostModeRegister('phone_number')}
                  errorText={enableLostModeFormState.errors.phone_number?.message}
                />
                <TextInput
                  label={t(
                    'inventory_mobile.device_page.management_tab.commands_tab.commands_section.modals.enable_lost_mode.field_titles.footnote'
                  )}
                  type={TextInputType.TEXT}
                  register={EnableLostModeRegister('footnote')}
                />
              </form>
            )}
            {pendingCommand === 'DisableLostMode' && (
              <div className={css.ModalText}>
                <p>
                  {t(
                    'inventory_mobile.device_page.management_tab.commands_tab.commands_section.modals.disable_lost_mode.message'
                  )}
                </p>
              </div>
            )}
          </Modal>
        </fieldset>
      </form>
    </>
  ) : (
    <BlankPage text={t('inventory.device_page.management_tab.commands_tab.device_offline')} />
  );
};
