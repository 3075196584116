import { mixed, object, string } from 'yup';
import { fileExtension, fileNotEmpty } from '../../../../utils/yup.validations';
import i18next from '../../../../i18n/i18n';

export interface FilevaultEscrowCertificateForm {
  certificate: FileList;
  secret: string;
}

export const filevaultEscrowCertificateFormSchema = object({
  certificate: mixed<FileList>()
    .test(
      fileNotEmpty(
        i18next.t('settings.tiles.filevault_escrow_certificate.page.error_certificate_required')
      )
    )
    .test(fileExtension(['pem', 'key'])),
  secret: string().required(
    i18next.t('settings.tiles.filevault_escrow_certificate.page.error_certificate_secret_required')
  )
}).required();
