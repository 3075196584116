import DataTable, { SortOrder } from 'react-data-table-component';
import { FC, useEffect, useState } from 'react';
import { getDevices, getDevicesCSV, searchDevices } from '../../../api/device';
import css from './inventory.module.scss';
import {
  DeviceOsType,
  DeviceSimpleDto,
  DevicesSortField,
  PaginationSortOrder,
  SearchDeviceByConditionDto
} from '../../../types/api';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { InventoryDevicePage } from './components/inventory-device-page/inventory-device-page.component';
import { TableColumn } from 'react-data-table-component/dist/src/DataTable/types';
import { formatDate } from '../../../utils/time.utils';
import { SearchBar } from './components/search-bar/search-bar-component';
import { Button } from '../../components/button/button.component';
import { useTranslation } from 'react-i18next';
import { PaginationRowsPerPageOptions } from '../../../const/pagination.const';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs.component';
import useDeviceSection, { DeviceType } from '../../contexts/device-section.context';

export const Inventory: FC = () => {
  const [searchedDeviceConditions, setSearchedDeviceConditions] = useState<
    SearchDeviceByConditionDto[]
  >([]);
  const [deviceData, setDeviceData] = useState<DeviceSimpleDto[]>([]);
  const [devicesCount, setDevicesCount] = useState(0);
  const [isSearchPerformed, setIsSearchPerformed] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortField, setSortField] = useState<DevicesSortField>(DevicesSortField.DisplayName);
  const [sortOrder, setSortOrder] = useState<PaginationSortOrder>(PaginationSortOrder.Asc);
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation();
  const { deviceType } = useDeviceSection();
  const osType = deviceType === DeviceType.COMPUTERS ? DeviceOsType.MacOS : DeviceOsType.IOS;
  const navigate = useNavigate();
  const location = useLocation();

  const getDevicesInfo = async () => {
    const response = isSearchPerformed
      ? await searchDevices({
          conditions: searchedDeviceConditions,
          page: currentPage,
          limit: rowsPerPage,
          sort_field: sortField,
          sort_order: sortOrder,
          os_type: osType
        })
      : await getDevices({
          page: currentPage,
          limit: rowsPerPage,
          sort_field: sortField,
          sort_order: sortOrder,
          os_type: osType
        });

    setDeviceData(response.devices);
    setDevicesCount(response.count);
  };

  const handleRowClick = (deviceId: string) => {
    setSearchedDeviceConditions([]);
    setIsSearchPerformed(false);
    navigate(deviceId);
  };

  const handleChangePage = async (value: number) => {
    setCurrentPage(value);
  };

  const handleChangeRowsPerPage = async (value: number) => {
    if (value > rowsPerPage) {
      setCurrentPage(Math.max(currentPage - 1, 1));
    }
    setRowsPerPage(value);
  };

  const handleSort = async (
    selectedColumn: TableColumn<DeviceSimpleDto>,
    sortDirection: SortOrder
  ) => {
    if (selectedColumn.sortField) setSortField(selectedColumn.sortField as DevicesSortField);
    setSortOrder(sortDirection as string as PaginationSortOrder);
  };

  const handleResetClick = () => {
    setSearchedDeviceConditions([]);
    setIsSearchPerformed(false);
  };

  const onSearch = (conditions: SearchDeviceByConditionDto[]) => {
    setSearchedDeviceConditions(conditions);
    setIsSearchPerformed(true);
    setCurrentPage(1);
  };

  useEffect(() => {
    getDevicesInfo();
  }, [
    rowsPerPage,
    sortOrder,
    sortField,
    currentPage,
    isSearchPerformed,
    searchedDeviceConditions,
    location
  ]);

  const handleClickExportCSV = async () => {
    getDevicesCSV({
      is_filtered: isSearchPerformed,
      conditions: searchedDeviceConditions,
      os_type: osType
    });

    const data = await getDevicesCSV({
      is_filtered: isSearchPerformed,
      conditions: searchedDeviceConditions,
      os_type: osType
    });
    const blob = new Blob([data]);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', 'export.csv');
    link.click();
  };

  const columns: TableColumn<DeviceSimpleDto>[] = [
    {
      name: t('inventory.table.heading.name'),
      selector: (row) =>
        row.device_information?.device_name ? row.device_information?.device_name : '',
      sortField: DevicesSortField.DisplayName,
      sortable: true
    },
    {
      name: t('inventory.table.heading.model_name'),
      selector: (row) => (row.model_name ? row.model_name : ''),
      sortField: DevicesSortField.ModelName,
      sortable: true,
      style: { cursor: 'pointer' }
    },
    {
      name: t('inventory.table.heading.os'),
      selector: (row) =>
        row.device_information && row.device_information.os_version
          ? row.device_information.os_version
          : '',
      sortField: DevicesSortField.OsVersion,
      sortable: true,
      style: { cursor: 'pointer' }
    },
    {
      name: t('inventory.table.heading.serial'),
      selector: (row) => (row.serial_number ? row.serial_number : ''),
      sortField: DevicesSortField.SerialNumber,
      sortable: true,
      style: { cursor: 'pointer' }
    },
    {
      name: t('inventory.table.heading.last_check_in'),
      selector: (row) => formatDate(row.last_seen),
      sortField: DevicesSortField.LastSeen,
      sortable: true,
      style: { cursor: 'pointer' }
    }
  ];

  return (
    <div id="inventory" className={css.InventoryContainer}>
      <Routes>
        <Route
          path=""
          element={
            <div>
              <Breadcrumbs />
              <SearchBar
                onSearch={onSearch}
                onReset={handleResetClick}
                isSearchPerformed={isSearchPerformed}
              />
              <div className={css.DataTableContainer}>
                <DataTable
                  columns={columns}
                  data={deviceData}
                  onRowClicked={(row) => {
                    handleRowClick(row.id);
                  }}
                  noDataComponent={t('common.no_records_in_table')}
                  sortServer
                  onSort={handleSort}
                  pagination
                  paginationServer
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  paginationDefaultPage={currentPage}
                  paginationPerPage={rowsPerPage}
                  paginationTotalRows={devicesCount}
                  paginationRowsPerPageOptions={PaginationRowsPerPageOptions}
                  paginationComponentOptions={{
                    rowsPerPageText: t('common.table.rows_per_page'),
                    rangeSeparatorText: t('common.table.of')
                  }}
                />
              </div>
              <div className={css.InventoryActions}>
                {devicesCount > 0 && (
                  <Button theme="primary" onClick={handleClickExportCSV}>
                    {t('inventory.export_btn')}
                  </Button>
                )}
              </div>
            </div>
          }
        />
        <Route path=":deviceId" element={<InventoryDevicePage />} />
      </Routes>
    </div>
  );
};
