import { MicroMdmCommandRequestType } from '../types/api';
import i18next from '../i18n/i18n';

export const commandRequestTypes: Record<MicroMdmCommandRequestType, string> = {
  [MicroMdmCommandRequestType.DeviceInformation]: i18next.t(
    'inventory.device_page.management_tab.commands_tab.pending_commands_section.table.command_types.device_information'
  ),
  [MicroMdmCommandRequestType.InstalledApplicationList]: i18next.t(
    'inventory.device_page.management_tab.commands_tab.pending_commands_section.table.command_types.installed_application_list'
  ),
  [MicroMdmCommandRequestType.SecurityInfo]: i18next.t(
    'inventory.device_page.management_tab.commands_tab.pending_commands_section.table.command_types.security_info'
  ),
  [MicroMdmCommandRequestType.RestartDevice]: i18next.t(
    'inventory.device_page.management_tab.commands_tab.pending_commands_section.table.command_types.restart_device'
  ),
  [MicroMdmCommandRequestType.InstallApplication]: i18next.t(
    'inventory.device_page.management_tab.commands_tab.pending_commands_section.table.command_types.install_application'
  ),
  [MicroMdmCommandRequestType.InstallProfile]: i18next.t(
    'inventory.device_page.management_tab.commands_tab.pending_commands_section.table.command_types.install_profile'
  ),
  [MicroMdmCommandRequestType.RemoveProfile]: i18next.t(
    'inventory.device_page.management_tab.commands_tab.pending_commands_section.table.command_types.remove_profile'
  ),
  [MicroMdmCommandRequestType.DeviceLock]: i18next.t(
    'inventory.device_page.management_tab.commands_tab.pending_commands_section.table.command_types.device_lock'
  ),
  [MicroMdmCommandRequestType.ScheduleOSUpdate]: i18next.t(
    'inventory.device_page.management_tab.commands_tab.pending_commands_section.table.command_types.schedule_os_update'
  ),
  [MicroMdmCommandRequestType.OSUpdateStatus]: i18next.t(
    'inventory.device_page.management_tab.commands_tab.pending_commands_section.table.command_types.os_update_status'
  ),
  [MicroMdmCommandRequestType.EraseDevice]: i18next.t(
    'inventory.device_page.management_tab.commands_tab.pending_commands_section.table.command_types.erase_device'
  ),
  [MicroMdmCommandRequestType.Settings]: i18next.t(
    'inventory.device_page.management_tab.commands_tab.pending_commands_section.table.command_types.settings'
  ),
  [MicroMdmCommandRequestType.ActivationLockBypassCode]: i18next.t(
    'inventory.device_page.management_tab.commands_tab.pending_commands_section.table.command_types.activation_lock_bypass_code'
  ),
  [MicroMdmCommandRequestType.EnableRemoteDesktop]: i18next.t(
    'inventory.device_page.management_tab.commands_tab.pending_commands_section.table.command_types.enable_remote_desktop'
  ),
  [MicroMdmCommandRequestType.DisableRemoteDesktop]: i18next.t(
    'inventory.device_page.management_tab.commands_tab.pending_commands_section.table.command_types.disable_remote_desktop'
  ),
  [MicroMdmCommandRequestType.SetFirmwarePassword]: i18next.t(
    'inventory.device_page.management_tab.commands_tab.pending_commands_section.table.command_types.set_firmware_password'
  ),
  [MicroMdmCommandRequestType.SetRecoveryLock]: i18next.t(
    'inventory.device_page.management_tab.commands_tab.pending_commands_section.table.command_types.set_recovery_lock'
  ),
  [MicroMdmCommandRequestType.ClearPasscode]: i18next.t(
    'inventory.device_page.management_tab.commands_tab.pending_commands_section.table.command_types.clear_passcode'
  ),
  [MicroMdmCommandRequestType.ProfileList]: i18next.t(
    'inventory.device_page.management_tab.commands_tab.pending_commands_section.table.command_types.profile_list'
  ),
  [MicroMdmCommandRequestType.ProvisioningProfileList]: i18next.t(
    'inventory.device_page.management_tab.commands_tab.pending_commands_section.table.command_types.provisioning_profile_list'
  ),
  [MicroMdmCommandRequestType.CertificateList]: i18next.t(
    'inventory.device_page.management_tab.commands_tab.pending_commands_section.table.command_types.certificate_list'
  ),
  [MicroMdmCommandRequestType.EnableLostMode]: i18next.t(
    'inventory.device_page.management_tab.commands_tab.pending_commands_section.table.command_types.certificate_list'
  ),
  [MicroMdmCommandRequestType.DisableLostMode]: i18next.t(
    'inventory.device_page.management_tab.commands_tab.pending_commands_section.table.command_types.certificate_list'
  )
};
