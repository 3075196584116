import ringoImg from '../../../assets/images/logo.png';
import {
  faPassport,
  faGears,
  faFolderPlus,
  faFolder,
  faArrowRightToBracket,
  faUsers,
  faLaptop,
  faMobileScreenButton
} from '@fortawesome/free-solid-svg-icons';
import css from './sidebar.module.scss';
import { DeviceSections, Paths } from '../../constants';
import { FC, useState } from 'react';
import { NavItem } from './components/nav-item.component';
import { useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { config } from '../../../config';
import { useTranslation } from 'react-i18next';
import { Restricted } from '../common/restricted';
import { Permission } from '../../../types/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useDeviceSection, { DeviceType } from '../../contexts/device-section.context';

enum SelectedTab {
  COMPUTER,
  MOBILE,
  DEVICE_USERS
}

export const Sidebar: FC = () => {
  const location = useLocation();
  const { deviceType } = useDeviceSection();
  const { t } = useTranslation();
  const defaultSelectedTab = (): SelectedTab => {
    if (location.pathname.startsWith(DeviceSections.MOBILE)) {
      return SelectedTab.MOBILE;
    }
    if (location.pathname.startsWith(DeviceSections.COMPUTERS)) {
      return SelectedTab.COMPUTER;
    }
    if (location.pathname.startsWith('/device-users')) {
      return SelectedTab.DEVICE_USERS;
    }
    return SelectedTab.COMPUTER;
  };
  const [selectedTab, setSelectedTab] = useState(defaultSelectedTab);
  const navigate = useNavigate();

  const getSelectedSection = () => {
    return selectedTab === SelectedTab.COMPUTER ? DeviceSections.COMPUTERS : DeviceSections.MOBILE;
  };

  const selectedSectionPath = getSelectedSection();

  const handleTabSelect = (tab: SelectedTab) => () => {
    setSelectedTab(tab);
    if (tab === SelectedTab.DEVICE_USERS) {
      navigate(Paths.DEVICE_USER_LIST);
    } else {
      const selectedPath =
        tab === SelectedTab.COMPUTER ? DeviceSections.COMPUTERS : DeviceSections.MOBILE;
      navigate(`${selectedPath}/${Paths.INVENTORY}`);
    }
  };

  const pathnameWithoutSection = location.pathname.replace(/^\/(computers|mobile)?\/?/, '');
  return (
    <>
      <aside className={`main-sidebar sidebar-dark-primary elevation-4 ${css.Sidebar}`}>
        {/* Brand Logo */}
        <a className="brand-link logo-switch" href="/">
          <img src={ringoImg} alt="Ringo Logo" className={cn(css.Logo, 'brand-image logo-xl')} />
        </a>
        <ul
          className={cn('nav nav-tabs', css.TabList)}
          id="custom-content-below-tab"
          role="tablist"
        >
          <li className={`nav-item ${css.Tab}`}>
            <a
              className={cn('nav-link', selectedTab === SelectedTab.COMPUTER && 'active')}
              onClick={handleTabSelect(SelectedTab.COMPUTER)}
            >
              <FontAwesomeIcon icon={faLaptop} />
            </a>
          </li>
          <li className={`nav-item ${css.Tab}`}>
            <a
              className={cn('nav-link', selectedTab === SelectedTab.MOBILE && 'active')}
              onClick={handleTabSelect(SelectedTab.MOBILE)}
            >
              <FontAwesomeIcon icon={faMobileScreenButton} />
            </a>
          </li>
          <Restricted to={[Permission.ReadDeviceUsers, Permission.EditDeviceUsers]}>
            <li className={`nav-item ${css.Tab}`}>
              <a
                className={cn('nav-link', selectedTab === SelectedTab.DEVICE_USERS && 'active')}
                onClick={handleTabSelect(SelectedTab.DEVICE_USERS)}
              >
                <FontAwesomeIcon icon={faUsers} />
              </a>
            </li>
          </Restricted>
        </ul>
        <div className="sidebar">
          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {selectedTab === SelectedTab.COMPUTER && (
                <>
                  {/* Add icons to the links using the .nav-icon class
           with font-awesome or any other icon font library */}
                  <Restricted to={[Permission.ReadDevices, Permission.EditDevices]}>
                    <NavItem
                      active={
                        deviceType === DeviceType.COMPUTERS &&
                        pathnameWithoutSection.startsWith(Paths.INVENTORY)
                      }
                      text={t('sidebar.inventory')}
                      icon={faLaptop}
                      link={`${selectedSectionPath}/${Paths.INVENTORY}`}
                    />
                  </Restricted>
                  <Restricted to={[Permission.ReadPolicies, Permission.EditPolicies]}>
                    <NavItem
                      active={
                        deviceType === DeviceType.COMPUTERS &&
                        pathnameWithoutSection.startsWith(Paths.POLICY_LIST)
                      }
                      text={t('sidebar.policies')}
                      icon={faPassport}
                      link={`${selectedSectionPath}/${Paths.POLICY_LIST}`}
                    />
                  </Restricted>
                  <Restricted to={[Permission.ReadProfiles, Permission.EditProfiles]}>
                    <NavItem
                      active={
                        deviceType === DeviceType.COMPUTERS &&
                        pathnameWithoutSection.startsWith(Paths.CONFIGURATION_PROFILE_LIST)
                      }
                      text={t('sidebar.profiles')}
                      icon={faGears}
                      link={`${selectedSectionPath}/${Paths.CONFIGURATION_PROFILE_LIST}`}
                    />
                  </Restricted>
                  {/* <NavItem text="App Store apps" icon={faAppStore} /> */}
                  {/* <NavItem text="Package management" icon={faBoxOpen} /> */}
                  <Restricted to={[Permission.ReadGroups, Permission.EditGroups]}>
                    <NavItem
                      active={
                        deviceType === DeviceType.COMPUTERS &&
                        pathnameWithoutSection.startsWith(Paths.SMART_GROUP_DEVICES_LIST)
                      }
                      text={t('sidebar.smart_groups')}
                      icon={faFolderPlus}
                      link={`${selectedSectionPath}/${Paths.SMART_GROUP_DEVICES_LIST}`}
                    />
                    <NavItem
                      active={
                        deviceType === DeviceType.COMPUTERS &&
                        pathnameWithoutSection.startsWith(Paths.STATIC_GROUP_DEVICES_LIST)
                      }
                      text={t('sidebar.static_groups')}
                      icon={faFolder}
                      link={`${selectedSectionPath}/${Paths.STATIC_GROUP_DEVICES_LIST}`}
                    />
                  </Restricted>
                </>
              )}
              {selectedTab === SelectedTab.MOBILE && (
                <>
                  {/* Add icons to the links using the .nav-icon class
           with font-awesome or any other icon font library */}
                  <Restricted to={[Permission.ReadDevices, Permission.EditDevices]}>
                    <NavItem
                      active={
                        deviceType === DeviceType.MOBILE &&
                        pathnameWithoutSection.startsWith(Paths.INVENTORY)
                      }
                      text={t('sidebar.inventory')}
                      icon={faMobileScreenButton}
                      link={`${selectedSectionPath}/${Paths.INVENTORY}`}
                    />
                  </Restricted>
                  <Restricted to={[Permission.ReadProfiles, Permission.EditProfiles]}>
                    <NavItem
                      active={
                        deviceType === DeviceType.MOBILE &&
                        pathnameWithoutSection.startsWith(Paths.CONFIGURATION_PROFILE_LIST)
                      }
                      text={t('sidebar.profiles')}
                      icon={faGears}
                      link={`${selectedSectionPath}/${Paths.CONFIGURATION_PROFILE_LIST}`}
                    />
                  </Restricted>
                  {/* <NavItem text="App Store apps" icon={faAppStore} /> */}
                  {/* <NavItem text="Package management" icon={faBoxOpen} /> */}
                  <Restricted to={[Permission.ReadGroups, Permission.EditGroups]}>
                    <NavItem
                      active={
                        deviceType === DeviceType.MOBILE &&
                        pathnameWithoutSection.startsWith(Paths.SMART_GROUP_DEVICES_LIST)
                      }
                      text={t('sidebar.smart_groups')}
                      icon={faFolderPlus}
                      link={`${selectedSectionPath}/${Paths.SMART_GROUP_DEVICES_LIST}`}
                    />
                    <NavItem
                      active={
                        deviceType === DeviceType.MOBILE &&
                        pathnameWithoutSection.startsWith(Paths.STATIC_GROUP_DEVICES_LIST)
                      }
                      text={t('sidebar.static_groups')}
                      icon={faFolder}
                      link={`${selectedSectionPath}/${Paths.STATIC_GROUP_DEVICES_LIST}`}
                    />
                  </Restricted>
                </>
              )}
              {selectedTab === SelectedTab.DEVICE_USERS && (
                <>
                  <Restricted to={[Permission.ReadDeviceUsers, Permission.EditDeviceUsers]}>
                    <NavItem
                      active={/^device-users\/?([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})?$/.test(
                        pathnameWithoutSection
                      )}
                      text={t('sidebar.device_users')}
                      icon={faUsers}
                      link={Paths.DEVICE_USER_LIST}
                    />
                  </Restricted>
                  <Restricted to={[Permission.ReadGroups, Permission.EditGroups]}>
                    <NavItem
                      active={pathnameWithoutSection.startsWith(
                        Paths.STATIC_GROUP_DEVICE_USERS_LIST
                      )}
                      text={t('sidebar.static_groups')}
                      icon={faFolder}
                      link={Paths.STATIC_GROUP_DEVICE_USERS_LIST}
                    />
                    <NavItem
                      active={pathnameWithoutSection.startsWith(
                        Paths.SMART_GROUP_DEVICE_USERS_LIST
                      )}
                      text={t('sidebar.smart_groups')}
                      icon={faFolder}
                      link={Paths.SMART_GROUP_DEVICE_USERS_LIST}
                    />
                  </Restricted>
                </>
              )}
              {selectedTab !== SelectedTab.DEVICE_USERS && (
                <NavItem
                  text={t('sidebar.enroll')}
                  icon={faArrowRightToBracket}
                  external
                  link={`${config.BACKEND_URL}/enroll`}
                />
              )}
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
    </>
  );
};
