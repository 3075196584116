/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AuthLoginRequestDto {
  username: string;
  password: string;
}

export enum Permission {
  ReadDevices = 'ReadDevices',
  EditDevices = 'EditDevices',
  ReadGroups = 'ReadGroups',
  EditGroups = 'EditGroups',
  ReadProfiles = 'ReadProfiles',
  EditProfiles = 'EditProfiles',
  ReadPolicies = 'ReadPolicies',
  EditPolicies = 'EditPolicies',
  ReadAdministration = 'ReadAdministration',
  EditAdministration = 'EditAdministration',
  ReadCommands = 'ReadCommands',
  EditCommands = 'EditCommands',
  ReadScripts = 'ReadScripts',
  EditScripts = 'EditScripts',
  ReadPackages = 'ReadPackages',
  EditPackages = 'EditPackages',
  ReadDeviceUsers = 'ReadDeviceUsers',
  EditDeviceUsers = 'EditDeviceUsers'
}

export interface RoleSimpleDto {
  /** @format uuid */
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  title: string;
  /**
   * List of permissions
   * @example ["ReadDevices","EditDevices","ReadGroups","EditGroups","ReadProfiles","EditProfiles","ReadPolicies","EditPolicies","ReadAdministration","EditAdministration","ReadCommands","EditCommands","ReadScripts","EditScripts","ReadPackages","EditPackages","ReadDeviceUsers","EditDeviceUsers"]
   */
  permissions?: Permission[];
}

export interface AuthLoginResponseDto {
  id: string;
  role: RoleSimpleDto;
  username: string;
  access_token: string;
}

export interface AuthRefreshResponseDto {
  id: string;
  role: RoleSimpleDto;
  username: string;
  access_token: string;
}

export enum UserOriginType {
  Local = 'local',
  Ldap = 'ldap'
}

export interface UserSimpleDto {
  /** @format uuid */
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  username: string;
  display_name: string;
  role: RoleSimpleDto;
  origin_type: UserOriginType;
}

export interface CreateUserRequestDto {
  /** Имя пользователя */
  username: string;
  /** Пароль */
  password?: string;
  /** Отображаемое имя */
  display_name?: string;
  /**
   * UUID Роли пользователя
   * @format uuid
   */
  role: string;
  /** Тип источника */
  origin_type: UserOriginType;
}

export interface GetUserListResponseDto {
  count: number;
  users: UserSimpleDto[];
}

export interface UpdateUserRequestDto {
  /** Имя пользователя */
  username?: string;
  /** Пароль */
  password?: string;
  /** Отображаемое имя */
  display_name?: string;
  /**
   * UUID Роли пользователя
   * @format uuid
   */
  role?: string;
}

export interface LdapInstanceMappingsResponseDto {
  username: string;
  full_name: string;
  email: string;
}

export interface LdapInstanceFullDto {
  /** @format uuid */
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  display_name: string;
  hostname: string;
  ssl: boolean;
  port: string | null;
  base_dn: string | null;
  filter: string | null;
  user_dn: string | null;
  password: string | null;
  sync_enabled: boolean;
  sync_interval: number;
  timeout_connect: number;
  timeout_search: number;
  has_ca_certificate: boolean;
  mappings: LdapInstanceMappingsResponseDto;
}

export interface LdapInstanceMappingsRequestDto {
  username?: string;
  full_name?: string;
  email?: string;
}

export interface SaveLdapInstanceDto {
  display_name: string;
  hostname: string;
  ssl?: boolean;
  port?: string | null;
  base_dn?: string | null;
  filter?: string | null;
  user_dn?: string | null;
  password?: string | null;
  sync_enabled?: boolean;
  /**
   * @min 60
   * @max 43200
   */
  sync_interval?: number;
  /**
   * @min 5
   * @max 120
   */
  timeout_connect?: number;
  /**
   * @min 5
   * @max 120
   */
  timeout_search?: number;
  ca_certificate?: string;
  /** LdapInstanceMappingsRequestDto */
  mappings?: LdapInstanceMappingsRequestDto;
}

export interface TestLdapConnectionDto {
  hostname: string;
  ssl: boolean;
  port?: string;
  base_dn?: string;
  user_dn?: string;
  password?: string;
  ca_certificate?: string;
  /**
   * @min 5
   * @max 120
   */
  timeout_connect?: number;
  /**
   * @min 5
   * @max 120
   */
  timeout_search?: number;
}

export interface SearchLdapUsersRequestDto {
  search?: string;
}

export interface SearchLdapUsersEntryDto {
  dn: string;
  username: string[];
  full_name: string[];
  email: string[];
}

export interface SearchLdapUsersResponseDto {
  entries: SearchLdapUsersEntryDto[];
}

export interface CreateRoleDto {
  title: string;
  /**
   * List of permissions
   * @example ["ReadDevices","EditDevices","ReadGroups","EditGroups","ReadProfiles","EditProfiles","ReadPolicies","EditPolicies","ReadAdministration","EditAdministration","ReadCommands","EditCommands","ReadScripts","EditScripts","ReadPackages","EditPackages","ReadDeviceUsers","EditDeviceUsers"]
   */
  permissions?: Permission[];
}

export interface GetRolesResponseDto {
  count: number;
  roles: RoleSimpleDto[];
}

export interface UpdateRoleDto {
  title?: string;
  /**
   * List of permissions
   * @example ["ReadDevices","EditDevices","ReadGroups","EditGroups","ReadProfiles","EditProfiles","ReadPolicies","EditPolicies","ReadAdministration","EditAdministration","ReadCommands","EditCommands","ReadScripts","EditScripts","ReadPackages","EditPackages","ReadDeviceUsers","EditDeviceUsers"]
   */
  permissions?: Permission[];
}

export enum LicenseStatus {
  Expired = 'expired',
  Active = 'active'
}

export enum DeviceOsType {
  IOS = 'iOS',
  MacOS = 'macOS'
}

export interface LicenseDto {
  license_code: string;
  /** @format date-time */
  expires_at?: string | null;
  /** @format date-time */
  activated_at?: string | null;
  max_device?: number;
  status?: LicenseStatus | null;
  os_type: DeviceOsType;
}

export interface GetActiveLicensesDto {
  macos_license: LicenseDto;
  ios_license: LicenseDto;
}

export interface GetLicenseListDto {
  licenses: LicenseDto[];
  count: number;
}

export interface UploadLicenseFileRequestDto {
  /** @format binary */
  license: File;
  os_type: DeviceOsType;
}

export interface DeviceActivationInfoDto {
  activation_lock_bypass_code?: string | null;
  error?: string | null;
}

export enum CommandStatus {
  Pending = 'pending',
  Failed = 'failed'
}

export interface GetCommandsRequestDto {
  status: CommandStatus;
}

export enum DevicesSortField {
  DisplayName = 'display_name',
  ModelName = 'model_name',
  ModelAndSerial = 'model_and_serial',
  OsVersion = 'os_version',
  SerialNumber = 'serial_number',
  LastSeen = 'last_seen'
}

export enum PaginationSortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export interface GetDevicesRequestDto {
  /** @min 1 */
  page?: number;
  /** @min 1 */
  limit?: number;
  sort_field?: DevicesSortField;
  sort_order?: PaginationSortOrder;
  os_type: DeviceOsType;
  get_all?: boolean;
}

export interface SearchDeviceByConditionDto {
  criteria: string;
  operator: '=' | '!=' | 'like' | 'not like' | '>' | '>=' | '<' | '<=';
  value: string;
  conjunctive?: 'and' | 'or' | null;
  start_bracket?: boolean;
  end_bracket?: boolean;
}

export interface GetDevicesCSVRequestDto {
  is_filtered: boolean;
  os_type: DeviceOsType;
  conditions?: SearchDeviceByConditionDto[] | null;
}

export interface GetCriterionValuesRequestDto {
  os_type: DeviceOsType;
  criterion: string;
}

export interface GetCriteriaRequestDto {
  forSearch: boolean;
}

export interface SetFirmwarePasswordResponseDto {
  error?: string | null;
}

export interface SetRecoveryLockResponseDto {
  error?: string | null;
}

export interface ClearPasscodeResponseDto {
  error?: string | null;
}

export interface EnableLostModeRequestDto {
  footnote?: string;
  message?: string;
  phone_number?: string;
}

export interface EnableLostModeResponseDto {
  error?: string | null;
}

export enum DeviceCriteriaType {
  String = 'string',
  Number = 'number',
  Boolean = 'boolean',
  Date = 'date'
}

export interface DeviceCriteriaDto {
  name: string;
  type: DeviceCriteriaType;
}

export interface DeviceCriteriaResponseDto {
  count: number;
  criteria: DeviceCriteriaDto[];
}

export interface DeviceLockMobileRequestDto {
  message?: string;
  phone_number?: string;
}

export interface DeviceInformationSimpleDto {
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  os_version: string | null;
  device_name: string | null;
}

export interface DeviceSimpleDto {
  id: string;
  udid: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  serial_number: string;
  model_name: string | null;
  agent_enrollment_status: boolean;
  mdm_enrollment_status: boolean;
  os_type: DeviceOsType;
  /** @format date-time */
  last_seen: string;
  dep_profile_status: string | null;
  device_information?: DeviceInformationSimpleDto;
}

export interface GetDevicesResponseDto {
  count: number;
  devices: DeviceSimpleDto[];
}

export interface GetDevicesCountResponseDto {
  count: number;
}

export interface SearchDevicesRequestDto {
  conditions: SearchDeviceByConditionDto[];
  os_type: DeviceOsType;
  /** @min 1 */
  page?: number;
  /** @min 1 */
  limit?: number;
  sort_field?: DevicesSortField;
  sort_order?: PaginationSortOrder;
  get_all?: boolean;
}

export enum MicroMdmCommandRequestType {
  DeviceInformation = 'DeviceInformation',
  InstalledApplicationList = 'InstalledApplicationList',
  SecurityInfo = 'SecurityInfo',
  RestartDevice = 'RestartDevice',
  InstallApplication = 'InstallApplication',
  InstallProfile = 'InstallProfile',
  RemoveProfile = 'RemoveProfile',
  DeviceLock = 'DeviceLock',
  ScheduleOSUpdate = 'ScheduleOSUpdate',
  OSUpdateStatus = 'OSUpdateStatus',
  EraseDevice = 'EraseDevice',
  Settings = 'Settings',
  ActivationLockBypassCode = 'ActivationLockBypassCode',
  EnableRemoteDesktop = 'EnableRemoteDesktop',
  DisableRemoteDesktop = 'DisableRemoteDesktop',
  SetFirmwarePassword = 'SetFirmwarePassword',
  SetRecoveryLock = 'SetRecoveryLock',
  ClearPasscode = 'ClearPasscode',
  ProfileList = 'ProfileList',
  ProvisioningProfileList = 'ProvisioningProfileList',
  CertificateList = 'CertificateList',
  EnableLostMode = 'EnableLostMode',
  DisableLostMode = 'DisableLostMode'
}

export interface CommandDto {
  /** @format uuid */
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  command_type: MicroMdmCommandRequestType;
}

export interface GetCommandsResponseDto {
  count: number;
  commands: CommandDto[];
}

export interface DeviceInformationFullDto {
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  os_version: string | null;
  device_name: string | null;
  device_capacity: number | null;
  available_device_capacity: number | null;
  used_space: number | null;
  is_supervised: boolean | null;
  software_update_device_id: string | null;
  local_ip: string | null;
  cpu_type: string | null;
  is_apple_silicon: boolean | null;
  main_network_interface: string | null;
  main_network_interface_mac: string | null;
  model_identifier: string | null;
  total_ram_mb: number | null;
  battery_capacity: string | null;
  os_name: string | null;
  os_build_version: string | null;
  os_rapid_security_response: string | null;
  os_ad_bind: boolean | null;
  firmware_password_enabled: boolean | null;
  firmware_password: string | null;
  ard_enabled: boolean | null;
  sip_enabled: boolean | null;
  activation_lock_enabled: boolean | null;
  activation_lock: string | null;
  x_protect_definitions_version: string | null;
  firewall_enabled: boolean | null;
  autologin_enabled: boolean | null;
  gatekeeper_enabled: boolean | null;
  recovery_lock_enabled: boolean | null;
  recovery_lock: string | null;
  primary_drive_serial: string | null;
  primary_drive_model: string | null;
  primary_drive_smart_status: string | null;
  primary_drive_revision: string | null;
  primary_drive_capacity_mb: number | null;
  filevault_enabled: boolean | null;
  filevault_has_institutional_key: boolean | null;
  filevault_has_personal_key: boolean | null;
  personal_recovery_key: string | null;
  lock_pin: string | null;
  time_zone: string | null;
  is_network_tethered: boolean | null;
  eas_device_identifier: string | null;
  is_do_not_disturb_in_effect: boolean | null;
  supplemental_os_version_extra: string | null;
  build_version: string | null;
  supplemental_build_version: string | null;
  is_cloud_backup_enabled: boolean | null;
  /** @format date-time */
  last_cloud_backup_date: string | null;
  battery_level: string | null;
  wifi_mac: string | null;
  bluetooth_mac: string | null;
  modem_firmware_version: string | null;
  is_device_locator_service_enabled: boolean | null;
  is_mdm_lost_mode_enabled: boolean | null;
  model: string | null;
  model_number: string | null;
  hardware_encryption_caps: number | null;
  passcode_compliant: boolean | null;
  passcode_compliant_with_profiles: boolean | null;
  passcode_lock_grace_period_enforced: number | null;
}

export interface AgentInstanceSimpleDto {
  /** @format uuid */
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  /** @default null */
  last_remote_address: string | null;
}

export interface DeviceApplicationSimpleDto {
  /** @format uuid */
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  name: string | null;
  version: string | null;
  path: string | null;
}

export interface DeviceProfileSimpleDto {
  /** @format uuid */
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  identifier: string | null;
  name: string | null;
  uuid: string | null;
}

export interface DeviceProvisioningProfileSimpleDto {
  /** @format uuid */
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  expiry_date: string | null;
  name: string | null;
  uuid: string | null;
}

export interface DeviceCertificateSimpleDto {
  /** @format uuid */
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  common_name: string | null;
  is_identity: boolean | null;
}

export interface DeviceUserAccountSimpleDto {
  /** @format uuid */
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  gid: string | null;
  uid: string | null;
  dir: string | null;
  name: string | null;
  is_admin: boolean | null;
}

export interface DeviceServiceSimpleDto {
  /** @format uuid */
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  name: string | null;
}

export interface DeviceUserSimpleDto {
  /** @format uuid */
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  username: string;
  email: string | null;
  employee_name: string | null;
  phone_number: string | null;
  position: string | null;
  computers_count?: number;
  mobile_devices_count?: number;
}

export interface ExtensionAttribute {
  display_name: string;
  description: string | null;
  data_type: 'String' | 'Number' | 'Date';
  inventory_display:
    | 'General'
    | 'Hardware'
    | 'OS'
    | 'Security'
    | 'Storage'
    | 'Applications'
    | 'ExtensionAttributes';
  input_type: 'Text' | 'Select' | 'Script';
  options: string[] | null;
  script: string | null;
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
}

export interface DeviceExtensionAttributeDto {
  /** @format uuid */
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  /** Дополнительный атрибут */
  extension_attribute: ExtensionAttribute | null;
  /** Значение дополнительного атрибута */
  value: string | null;
}

export interface DeviceFullDto {
  id: string;
  udid: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  serial_number: string;
  model_name: string | null;
  agent_enrollment_status: boolean;
  mdm_enrollment_status: boolean;
  os_type: DeviceOsType;
  /** @format date-time */
  last_seen: string;
  dep_profile_status: string | null;
  device_information?: DeviceInformationFullDto;
  agent_instance?: AgentInstanceSimpleDto;
  applications?: DeviceApplicationSimpleDto[];
  profiles?: DeviceProfileSimpleDto[];
  provisioning_profiles?: DeviceProvisioningProfileSimpleDto[];
  certificates?: DeviceCertificateSimpleDto[];
  user_accounts?: DeviceUserAccountSimpleDto[];
  services?: DeviceServiceSimpleDto[];
  device_user?: DeviceUserSimpleDto;
  device_extension_attributes?: DeviceExtensionAttributeDto[];
}

export interface LinkToUserDto {
  device_user_id: string;
}

export interface DeviceCommandResponseDto {
  udid: string;
  command_uuid: string | null;
  queued: boolean;
  completed: boolean;
  payload?: object | null;
}

export interface DeviceLockRequestDto {
  message: string;
  /**
   * @minLength 6
   * @maxLength 6
   */
  pin: string;
  phone_number?: string;
}

export interface EraseDeviceRequestDto {
  /**
   * @minLength 6
   * @maxLength 6
   */
  pin?: string;
}

export interface PerformOsUpdateActionDto {
  install_action: 'InstallASAP' | 'DownloadOnly';
  product_key?: string;
}

export interface SetFirmwarePasswordRequestDto {
  current_password?: string;
  new_password?: string;
}

export interface SetRecoveryLockRequestDto {
  current_password?: string;
  new_password?: string;
}

export interface UpdateExtensionAttributeValueDto {
  /** ID дополнительного атрибута */
  extension_attribute_id: string;
  /** Значение дополнительного атрибута */
  value?: string | null;
}

export interface UploadPushCertificateRequestDto {
  /** @format binary */
  certificate: File;
  /** @format binary */
  private_key: File;
  /** @example "secret" */
  password?: string;
}

export interface CheckAgentStatusResponseDto {
  online: boolean;
}

export interface SendAgentCommandRequestDto {
  input: string;
}

export interface SendAgentCommandResponseDto {
  output: string[];
  success: boolean | null;
}

export interface GetSettingsResponseDto {
  organization_name: string | null;
  filevault_escrow_cert_key: string | null;
  filevault_escrow_cert_key_secret: string | null;
  policy_custom_script_timeout: number | null;
  policy_leave_history_on_delete: boolean | null;
  get_device_information_timeout: number | null;
}

export interface CheckSettingsResponseDto {
  organization_name: boolean;
  filevault_escrow_cert_key: boolean;
  filevault_escrow_cert_key_secret: boolean;
  policy_custom_script_timeout: boolean;
  get_device_information_timeout: boolean;
  policy_leave_history_on_delete: boolean;
}

export interface UpdateSettingsDto {
  organization_name: string | null;
  filevault_escrow_cert_key: string | null;
  filevault_escrow_cert_key_secret: string | null;
  /**
   * @min 60
   * @max 86400
   */
  policy_custom_script_timeout: number | null;
  policy_leave_history_on_delete: boolean;
  /**
   * @min 60
   * @max 43200
   */
  get_device_information_timeout: number | null;
}

export enum ExtensionAttributeDataType {
  String = 'String',
  Number = 'Number',
  Date = 'Date'
}

export enum ExtensionAttributeInventorySection {
  General = 'General',
  Hardware = 'Hardware',
  OS = 'OS',
  Security = 'Security',
  Storage = 'Storage',
  Applications = 'Applications',
  ExtensionAttributes = 'ExtensionAttributes'
}

export enum ExtensionAttributeInputType {
  Text = 'Text',
  Select = 'Select',
  Script = 'Script'
}

export interface CreateExtensionAttributeDto {
  /** Наименование */
  display_name: string;
  /** Описание */
  description?: string;
  /**
   * Тип данных
   * @default "String"
   */
  data_type: ExtensionAttributeDataType;
  /**
   * Секция для отображения в информации об устройстве
   * @default "ExtensionAttributes"
   */
  inventory_display: ExtensionAttributeInventorySection;
  /**
   * Способ ввода
   * @default "Text"
   */
  input_type: ExtensionAttributeInputType;
  /** Варианты */
  options?: string[];
  /** Скрипт */
  script?: string;
}

export interface ExtensionAttributeFullDto {
  /** @format uuid */
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  /** Наименование */
  display_name: string;
  /** Описание */
  description: string | null;
  /**
   * Тип данных
   * @default "String"
   */
  data_type: ExtensionAttributeDataType;
  /**
   * Секция для отображения в информации об устройстве
   * @default "ExtensionAttributes"
   */
  inventory_display: ExtensionAttributeInventorySection;
  /**
   * Способ ввода
   * @default "Text"
   */
  input_type: ExtensionAttributeInputType;
  /** Варианты */
  options: string[] | null;
  /** Скрипт */
  script: string | null;
}

export interface GetExtensionAttributesResponseDto {
  /** Количество */
  count: number;
  /** Дополнительные атрибуты */
  extension_attributes: ExtensionAttributeFullDto[];
}

export interface UpdateExtensionAttributeDto {
  /** Наименование */
  display_name?: string;
  /** Описание */
  description?: string;
  /**
   * Тип данных
   * @default "String"
   */
  data_type?: ExtensionAttributeDataType;
  /**
   * Секция для отображения в информации об устройстве
   * @default "ExtensionAttributes"
   */
  inventory_display?: ExtensionAttributeInventorySection;
  /**
   * Способ ввода
   * @default "Text"
   */
  input_type?: ExtensionAttributeInputType;
  /** Варианты */
  options?: string[];
  /** Скрипт */
  script?: string;
}

export enum HistoryElementType {
  Device = 'device'
}

export enum HistorySortField {
  HistoryType = 'history_type',
  AdditionalInfo = 'additional_info',
  CreatedAt = 'created_at'
}

export interface GetHistoryRequestDto {
  element_type: HistoryElementType;
  element_id: string;
  start_time?: string | null;
  end_time?: string | null;
  /** @min 1 */
  page?: number;
  /** @min 1 */
  limit?: number;
  sort_field?: HistorySortField;
  sort_order?: PaginationSortOrder;
}

export enum HistoryMdmCommandType {
  Restart = 'restart',
  UnrollDevice = 'unroll_device',
  DownloadUpdates = 'download_updates',
  InstallUpdates = 'install_updates',
  EnableBluetooth = 'enable_bluetooth',
  DisableBluetooth = 'disable_bluetooth',
  WipeDevice = 'wipe_device',
  LockDevice = 'lock_device',
  SendBlankPush = 'send_blank_push',
  EnableArd = 'enable_ard',
  DisableArd = 'disable_ard',
  GetActivationLockBypassCode = 'get_activation_lock_bypass_code',
  SetFirmwarePassword = 'set_firmware_password',
  SetRecoveryLock = 'set_recovery_lock',
  ClearPasscode = 'clear_passcode',
  EnableLostMode = 'enable_lost_mode',
  DisableLostMode = 'disable_lost_mode'
}

export interface HistoryAdditionalInfoMdmCommand {
  command: HistoryMdmCommandType;
}

export interface HistoryAdditionalInfoSmartGroup {
  smart_group_id: string;
}

export interface HistoryAdditionalInfoStaticGroup {
  static_group_id: string;
}

export interface HistoryAdditionalInfoPolicy {
  policy_id: string;
}

export interface HistoryAdditionalInfoProfile {
  profile_id: string;
}

export interface DeviceInformation {
  device: Device;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  device_capacity: number | null;
  available_device_capacity: number | null;
  is_supervised: boolean | null;
  software_update_device_id: string | null;
  device_name: string | null;
  local_ip: string | null;
  cpu_type: string | null;
  is_apple_silicon: boolean | null;
  main_network_interface: string | null;
  main_network_interface_mac: string | null;
  model_identifier: string | null;
  total_ram_mb: number | null;
  battery_capacity: string | null;
  os_name: string | null;
  os_version: string | null;
  os_build_version: string | null;
  os_rapid_security_response: string | null;
  os_ad_bind: boolean | null;
  firmware_password_enabled: boolean | null;
  firmware_password: string | null;
  ard_enabled: boolean | null;
  sip_enabled: boolean | null;
  activation_lock_enabled: boolean | null;
  activation_lock: string | null;
  x_protect_definitions_version: string | null;
  firewall_enabled: boolean | null;
  autologin_enabled: boolean | null;
  gatekeeper_enabled: boolean | null;
  recovery_lock_enabled: boolean | null;
  recovery_lock: string | null;
  primary_drive_serial: string | null;
  personal_recovery_key: string | null;
  primary_drive_model: string | null;
  primary_drive_smart_status: string | null;
  primary_drive_revision: string | null;
  primary_drive_capacity_mb: number | null;
  filevault_enabled: boolean | null;
  filevault_has_institutional_key: boolean | null;
  filevault_has_personal_key: boolean | null;
  lock_pin: string | null;
  time_zone: string | null;
  is_network_tethered: boolean | null;
  eas_device_identifier: string | null;
  is_do_not_disturb_in_effect: boolean | null;
  supplemental_os_version_extra: string | null;
  build_version: string | null;
  supplemental_build_version: string | null;
  is_cloud_backup_enabled: boolean | null;
  /** @format date-time */
  last_cloud_backup_date: string | null;
  battery_level: string | null;
  wifi_mac: string | null;
  bluetooth_mac: string | null;
  modem_firmware_version: string | null;
  is_device_locator_service_enabled: boolean | null;
  is_mdm_lost_mode_enabled: boolean | null;
  unlock_token: string | null;
  model: string | null;
  model_number: string | null;
  hardware_encryption_caps: number | null;
  passcode_compliant: boolean | null;
  passcode_compliant_with_profiles: boolean | null;
  passcode_lock_grace_period_enforced: number | null;
}

export interface AgentInstance {
  device: Device;
  /** @default null */
  last_remote_address: string | null;
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
}

export interface DeviceUser {
  username: string;
  email: string | null;
  employee_name: string | null;
  phone_number: string | null;
  position: string | null;
  device: Device;
  /** @default [] */
  static_groups: object;
  mobile_devices_count: string;
  computers_count: string;
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
}

export interface Device {
  id: string;
  udid: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  serial_number: string;
  model_name: string | null;
  mdm_enrollment_status: boolean;
  agent_enrollment_status: boolean;
  remove_pending: boolean;
  /** @format date-time */
  last_seen: string;
  dep_profile_status: string | null;
  os_type: 'iOS' | 'macOS';
  device_information?: DeviceInformation;
  agent_instance?: AgentInstance;
  /** @default [] */
  applications: object;
  /** @default [] */
  profiles: object;
  /** @default [] */
  provisioning_profiles: object;
  /** @default [] */
  certificates: object;
  /** @default [] */
  user_accounts: object;
  /** @default [] */
  services: object;
  /** @default [] */
  device_extension_attributes: object;
  device_user: DeviceUser | null;
  display_name: string | null;
  model_and_serial: string | null;
}

export enum DeviceHistoryType {
  DeviceAdd = 'device:add',
  DeviceDelete = 'device:delete',
  DeviceEdit = 'device:edit',
  DeviceSmartGroupAdd = 'device:smart-group:add',
  DeviceSmartGroupRemove = 'device:smart-group:remove',
  DeviceStaticGroupAdd = 'device:static-group:add',
  DeviceStaticGroupRemove = 'device:static-group:remove',
  DeviceScopePolicy = 'device:scope:policy',
  DeviceScopeProfile = 'device:scope:profile',
  DeviceMdmCommandSent = 'device:mdm-command:sent',
  DeviceMdmCommandFailed = 'device:mdm-command:failed',
  DeviceTerminalStarted = 'device:terminal:started'
}

export interface HistoryDto {
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  element_type: 'device';
  element_id: string;
  device?: Device | null;
  history_type: DeviceHistoryType;
  additional_info?: object | null;
}

export interface GetHistoryResponseDto {
  count: number;
  history: HistoryDto[];
}

export enum DeviceUserCriteria {
  Username = 'Username',
  Email = 'Email',
  EmployeeName = 'EmployeeName',
  PhoneNumber = 'PhoneNumber',
  Position = 'Position',
  Group = 'Group'
}

export interface GetDeviceUsersCriteriaValuesRequestDto {
  criteria: DeviceUserCriteria;
}

export interface SearchDeviceUserByConditionDto {
  criteria: string;
  value: string;
}

export enum DeviceUserSortField {
  Username = 'username',
  Email = 'email',
  EmployeeName = 'employee_name',
  Position = 'position',
  PhoneNumber = 'phone_number',
  ComputersCount = 'computers_count',
  MobileDevicesCount = 'mobile_devices_count'
}

export interface GetDeviceUserListRequestDto {
  search?: string;
  conditions?: SearchDeviceUserByConditionDto[];
  /** @min 1 */
  page?: number;
  /** @min 1 */
  limit?: number;
  sort_field?: DeviceUserSortField;
  sort_order?: PaginationSortOrder;
}

export interface GetDeviceUserListResponseDto {
  count: number;
  users: DeviceUserSimpleDto[];
}

export interface GetDeviceUsersCriteriaResponseDto {
  count: number;
  criteria: DeviceUserCriteria[];
}

export interface DeviceUsersCriteriaValue {
  value: string;
  title: string | null;
}

export interface GetDeviceUsersCriteriaValuesResponseDto {
  values: DeviceUsersCriteriaValue[];
}

export interface CreateDeviceUserRequestDto {
  /** Имя пользователя */
  username: string;
  /** Email пользователя */
  email?: string;
  /** Фактическое имя сотрудника */
  employee_name?: string;
  /** Контактный номер телефона */
  phone_number?: string;
  /** Должность пользователя */
  position?: string;
}

export interface UpdateDeviceUserRequestDto {
  /** Имя пользователя */
  username?: string;
  /** Email пользователя */
  email?: string;
  /** Фактическое имя сотрудника */
  employee_name?: string;
  /** Контактный номер телефона */
  phone_number?: string;
  /** Должность пользователя */
  position?: string;
}

export interface UploadSpreadsheetRequestDto {
  /**
   * Файл таблицы
   * @format binary
   */
  spreadsheet: File;
  merge?: boolean;
}

export interface ScriptBasicDto {
  /** @format uuid */
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  display_name: string;
  description: string | null;
}

export interface GetScriptsResponseDto {
  count: number;
  scripts: ScriptBasicDto[];
}

export interface ScriptFullDto {
  /** @format uuid */
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  display_name: string;
  description: string | null;
  content: string;
}

export interface CreateScriptRequestDto {
  /** @example "Script name" */
  name: string;
  script: string;
  description?: string;
}

export interface UpdateScriptRequestDto {
  /** @example "Script name" */
  name?: string;
  script?: string;
  description?: string;
}

export enum PoliciesSortField {
  Title = 'title',
  Frequency = 'frequency',
  Trigger = 'trigger',
  Scope = 'scope',
  ScopedDevicesCount = 'scoped_devices_count',
  SuccessfulDistinctRunsCount = 'successful_distinct_runs_count'
}

export interface GetPoliciesRequestDto {
  /** @min 1 */
  page?: number;
  /** @min 1 */
  limit?: number;
  sort_field?: PoliciesSortField;
  sort_order?: PaginationSortOrder;
}

export interface GetPolicyRunsRequestDto {
  policy_id?: string;
  /** @min 1 */
  page?: number;
  /** @min 1 */
  limit?: number;
}

export enum PolicyTrigger {
  Immediate = 'Immediate',
  Interval = 'Interval',
  CustomEvent = 'CustomEvent'
}

export enum PolicyFrequency {
  Ongoing = 'Ongoing',
  OnceEveryDay = 'OnceEveryDay',
  OnceEveryWeek = 'OnceEveryWeek',
  OnceEveryMonth = 'OnceEveryMonth',
  OncePerComputer = 'OncePerComputer'
}

export interface PolicyConfiguration {
  triggers: PolicyTrigger[];
  frequency: PolicyFrequency;
  /**
   * @min 5
   * @max 43200
   * @example 30
   */
  interval_amount?: number;
}

export enum ScopeEntryOperationType {
  Include = 'Include',
  Exclude = 'Exclude'
}

export interface ScopeEntrySimpleDto {
  id: string;
  parent_type: 'Policy' | 'ConfigurationProfile';
  parent_id: string;
  target_type:
    | 'Device'
    | 'StaticGroupDevices'
    | 'SmartGroupDevices'
    | 'DeviceUser'
    | 'StaticGroupDeviceUsers'
    | 'SmartGroupDeviceUsers';
  target_id: string;
  target_title: string | null;
  operation_type: ScopeEntryOperationType;
}

export interface PolicySimpleDto {
  /** @format uuid */
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  title: string;
  enabled: boolean;
  scoped: boolean;
  configuration: PolicyConfiguration | null;
  scope_entries?: ScopeEntrySimpleDto[];
  successful_distinct_runs_count: number;
  scoped_devices_count: number;
  unacknowledged_failed_policy_run_count: number;
}

export interface GetPoliciesResponseDto {
  count: number;
  policies: PolicySimpleDto[];
}

export interface PolicyRunSimpleDto {
  /** @format uuid */
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  /** @format date-time */
  started_at: string | null;
  /** @format date-time */
  finished_at: string | null;
  in_progress: boolean;
  success: boolean | null;
  acknowledged: boolean;
  cancel_pending: boolean;
  canceled: boolean;
  device: DeviceSimpleDto | null;
}

export interface GetPolicyRunsResponseDto {
  count: number;
  policy_runs: PolicyRunSimpleDto[];
}

export interface AgentCommandSimpleDto {
  /** @format uuid */
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  request_type: 'GetDeviceInformation' | 'ModifyPackage' | 'UninstallAgent' | 'Custom';
  script: string | null;
  output_lines: number;
  /** @default null */
  arguments: string[] | null;
  queued: boolean;
  in_progress: boolean;
  success: boolean | null;
  exit_code: number | null;
  result: string[] | null;
  result_error: string[] | null;
  timed_out: boolean;
  cancel_pending: boolean;
  canceled: boolean;
}

export interface PolicyRunStepSimpleDto {
  /** @format uuid */
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  in_progress: boolean;
  success: boolean | null;
  command_id: string | null;
  agent_command: AgentCommandSimpleDto | null;
  configuration: object | null;
  type: 'agent' | 'mdm';
  command_type: 'RunCustomScript' | 'ModifyPackage' | 'GetDeviceInformation' | 'RestartComputer';
}

export interface PolicyRunFullDto {
  /** @format uuid */
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  /** @format date-time */
  started_at: string | null;
  /** @format date-time */
  finished_at: string | null;
  in_progress: boolean;
  success: boolean | null;
  acknowledged: boolean;
  cancel_pending: boolean;
  canceled: boolean;
  device: DeviceSimpleDto | null;
  steps: PolicyRunStepSimpleDto[];
}

export interface PolicyPayloadScript {
  script_id: string;
  script_arguments?: string[];
  script_title?: string;
}

export enum PolicyPayloadPackageDistributionType {
  Default = 'Default'
}

export enum PolicyPayloadPackageModifyType {
  Install = 'Install',
  Cache = 'Cache',
  InstallCached = 'InstallCached'
}

export interface PolicyPayloadPackagesItem {
  modify_type: PolicyPayloadPackageModifyType;
  package_id: string;
  package_title?: string;
}

export interface PolicyPayloadPackages {
  distribution_type: PolicyPayloadPackageDistributionType;
  items: PolicyPayloadPackagesItem[];
}

export interface PolicyPayloadMaintenance {
  update_inventory: boolean;
}

export interface PolicyPayload {
  scripts?: PolicyPayloadScript[];
  packages?: PolicyPayloadPackages;
  maintenance?: PolicyPayloadMaintenance;
}

export interface PolicyFullDto {
  /** @format uuid */
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  title: string;
  enabled: boolean;
  scoped: boolean;
  configuration: PolicyConfiguration | null;
  scope_entries?: ScopeEntrySimpleDto[];
  successful_distinct_runs_count: number;
  scoped_devices_count: number;
  unacknowledged_failed_policy_run_count: number;
  payload: PolicyPayload | null;
}

export interface CreatePolicyScopeRequestDto {
  type:
    | 'Device'
    | 'StaticGroupDevices'
    | 'SmartGroupDevices'
    | 'DeviceUser'
    | 'StaticGroupDeviceUsers'
    | 'SmartGroupDeviceUsers';
  operation: ScopeEntryOperationType;
  id: string;
}

export interface CreatePolicyRequestDto {
  /** @example "Policy title" */
  title: string;
  enabled?: boolean;
  scoped?: boolean;
  /** PolicyConfiguration */
  configuration: PolicyConfiguration;
  /** PolicyPayload */
  payload?: PolicyPayload | null;
  scope_entries?: CreatePolicyScopeRequestDto[];
}

export enum UpdatePolicyRerun {
  All = 'all',
  ScopeNew = 'scope_new'
}

export interface UpdatePolicyRequestDto {
  /** @example "Policy title" */
  title?: string;
  enabled?: boolean;
  scoped?: boolean;
  /** PolicyConfiguration */
  configuration?: PolicyConfiguration;
  /** PolicyPayload */
  payload?: PolicyPayload | null;
  scope_entries?: CreatePolicyScopeRequestDto[];
  rerun?: UpdatePolicyRerun;
}

export enum ScopeOptionType {
  Device = 'Device',
  StaticGroupDevices = 'StaticGroupDevices',
  SmartGroupDevices = 'SmartGroupDevices',
  DeviceUser = 'DeviceUser',
  StaticGroupDeviceUsers = 'StaticGroupDeviceUsers',
  SmartGroupDeviceUsers = 'SmartGroupDeviceUsers'
}

export interface GetScopeOptionsRequestDto {
  /** @min 1 */
  page?: number;
  /** @min 1 */
  limit?: number;
  sort_order?: PaginationSortOrder;
  type: ScopeOptionType;
  target_os_type?: DeviceOsType;
}

export interface ScopeOptionDto {
  type: ScopeOptionType;
  id: string;
  display_name: string;
}

export interface GetScopeOptionsDto {
  options: ScopeOptionDto[];
  count: number;
}

export enum SmartGroupDeviceUsersSortField {
  Title = 'title',
  DeviceUsersCount = 'device_users_count',
  Id = 'id',
  UpdatedAt = 'updated_at',
  CreatedAt = 'created_at'
}

export interface GetSmartGroupsDeviceUsersRequestDto {
  /** @min 1 */
  page?: number;
  /** @min 1 */
  limit?: number;
  sort_field?: SmartGroupDeviceUsersSortField;
  sort_order?: PaginationSortOrder;
  get_all?: boolean;
}

export enum SmartGroupDevicesSortField {
  Title = 'title',
  DevicesCount = 'devices_count',
  Id = 'id',
  UpdatedAt = 'updated_at',
  CreatedAt = 'created_at'
}

export interface GetSmartGroupsDevicesRequestDto {
  /** @min 1 */
  page?: number;
  /** @min 1 */
  limit?: number;
  sort_field?: SmartGroupDevicesSortField;
  sort_order?: PaginationSortOrder;
  os_type: DeviceOsType;
  get_all?: boolean;
}

export enum StaticGroupDeviceUsersSortField {
  Title = 'title',
  DeviceUsersCount = 'device_users_count',
  Id = 'id',
  UpdatedAt = 'updated_at',
  CreatedAt = 'created_at'
}

export interface GetStaticGroupsDeviceUsersRequestDto {
  /** @min 1 */
  page?: number;
  /** @min 1 */
  limit?: number;
  sort_field?: StaticGroupDeviceUsersSortField;
  sort_order?: PaginationSortOrder;
}

export enum StaticGroupDevicesSortField {
  Title = 'title',
  DevicesCount = 'devices_count',
  Id = 'id',
  UpdatedAt = 'updated_at',
  CreatedAt = 'created_at'
}

export interface GetStaticGroupsDevicesRequestDto {
  /** @min 1 */
  page?: number;
  /** @min 1 */
  limit?: number;
  sort_field?: StaticGroupDevicesSortField;
  sort_order?: PaginationSortOrder;
  os_type: DeviceOsType;
}

export interface GroupSimpleDto {
  /** @format uuid */
  id: string;
  title: string;
  type: 'StaticDevices' | 'SmartDevices' | 'StaticDeviceUsers' | 'SmartDeviceUsers';
}

export interface GetGroupsForDeviceDto {
  groups: GroupSimpleDto[];
}

export interface StaticGroupDevicesSimpleDto {
  /** @format uuid */
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  title: string;
  devices_count: number;
  os_type: 'iOS' | 'macOS';
}

export interface GetStaticGroupsDevicesResponseDto {
  count: number;
  groups: StaticGroupDevicesSimpleDto[];
}

export interface StaticGroupDevicesFullDto {
  /** @format uuid */
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  title: string;
  devices_count: number;
  os_type: 'iOS' | 'macOS';
  devices: DeviceSimpleDto[];
}

export interface CreateStaticGroupDevicesRequestDto {
  os_type: DeviceOsType;
  title: string;
  devices?: string[];
}

export interface StaticGroupDevices {
  title: string;
  os_type: 'iOS' | 'macOS';
  /** @default [] */
  devices: object;
  devices_count: number;
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
}

export interface UpdateStaticGroupDevicesRequestDto {
  title?: string;
  devices?: string[];
}

export interface StaticGroupDeviceUsersSimpleDto {
  /** @format uuid */
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  title: string;
  device_users_count: number;
}

export interface GetStaticGroupsDeviceUsersResponseDto {
  count: number;
  groups: StaticGroupDeviceUsersSimpleDto[];
}

export interface StaticGroupDeviceUsersFullDto {
  /** @format uuid */
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  title: string;
  device_users_count: number;
  device_user_ids: string[];
}

export interface CreateStaticGroupDeviceUsersRequestDto {
  title: string;
  device_users?: string[];
}

export interface UpdateStaticGroupDeviceUsersRequestDto {
  title?: string;
  device_users?: string[];
}

export interface SmartGroupDevicesSimpleDto {
  /** @format uuid */
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  title: string;
  devices_count: number;
  os_type: 'iOS' | 'macOS';
}

export interface GetSmartGroupsDevicesResponseDto {
  count: number;
  groups: SmartGroupDevicesSimpleDto[];
}

export interface SmartGroupDevicesConditionStrippedDto {
  criteria: string;
  operator: '=' | '!=' | 'like' | 'not like' | '>' | '>=' | '<' | '<=';
  value: string;
  conjunctive: 'and' | 'or' | null;
  start_bracket: boolean;
  end_bracket: boolean;
}

export interface SmartGroupDevicesFullDto {
  /** @format uuid */
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  title: string;
  devices_count: number;
  os_type: 'iOS' | 'macOS';
  conditions: SmartGroupDevicesConditionStrippedDto[];
  devices: DeviceSimpleDto[];
}

export interface GetDevicesFromSmartGroupResponseDto {
  count: number;
  devices: DeviceSimpleDto[];
}

export interface CreateSmartGroupDevicesConditionRequestDto {
  criteria: string;
  operator: '=' | '!=' | 'like' | 'not like' | '>' | '>=' | '<' | '<=';
  value: string;
  conjunctive?: 'and' | 'or' | null;
  start_bracket?: boolean;
  end_bracket?: boolean;
}

export interface CreateSmartGroupDevicesRequestDto {
  os_type: DeviceOsType;
  title: string;
  conditions?: CreateSmartGroupDevicesConditionRequestDto[];
}

export interface SmartGroupDevices {
  title: string;
  os_type: 'iOS' | 'macOS';
  /** @default [] */
  conditions: object;
  /** @default [] */
  devices: object;
  devices_count: number;
  is_wildcard?: boolean;
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
}

export interface UpdateSmartGroupDevicesRequestDto {
  title?: string;
  conditions?: CreateSmartGroupDevicesConditionRequestDto[];
}

export interface SmartGroupDeviceUsersSimpleDto {
  /** @format uuid */
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  title: string;
  device_users_count: number;
}

export interface GetSmartGroupsDeviceUsersResponseDto {
  count: number;
  groups: SmartGroupDeviceUsersSimpleDto[];
}

export interface SmartGroupDeviceUsersConditionStrippedDto {
  criteria: DeviceUserCriteria;
  operator: '=' | '!=' | 'like' | 'not like' | 'member of' | 'not member of';
  value: string;
  title: string | null;
  conjunctive: 'and' | 'or' | null;
  start_bracket: boolean;
  end_bracket: boolean;
}

export interface SmartGroupDeviceUsersFullDto {
  /** @format uuid */
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  title: string;
  device_users_count: number;
  conditions: SmartGroupDeviceUsersConditionStrippedDto[];
  device_users: DeviceUserSimpleDto[];
}

export interface CreateSmartGroupDeviceUsersConditionRequestDto {
  criteria: DeviceUserCriteria;
  operator: '=' | '!=' | 'like' | 'not like' | 'member of' | 'not member of';
  value: string;
  conjunctive?: 'and' | 'or' | null;
  start_bracket?: boolean;
  end_bracket?: boolean;
}

export interface CreateSmartGroupDeviceUsersRequestDto {
  title: string;
  conditions?: CreateSmartGroupDeviceUsersConditionRequestDto[];
}

export interface UpdateSmartGroupDeviceUsersRequestDto {
  title?: string;
  conditions?: CreateSmartGroupDeviceUsersConditionRequestDto[];
}

export interface PackageSimpleDto {
  /** @format uuid */
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  display_name: string;
  filename: string;
}

export interface GetPackagesResponseDto {
  count: number;
  packages: PackageSimpleDto[];
}

export interface CreatePackageDto {
  display_name: string;
  /**
   * @pattern /.+\.pkg$/
   * @example "application.pkg"
   */
  filename: string;
}

export interface UpdatePackageDto {
  display_name?: string;
  /**
   * @pattern /.+\.pkg$/
   * @example "application.pkg"
   */
  filename?: string;
}

export enum PackageDistributionPointType {
  Smb = 'smb',
  Http = 'http'
}

export interface PackagePointSimpleDto {
  /** @format uuid */
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  display_name: string;
  type: PackageDistributionPointType;
  server: string;
  share_name: string | null;
  port: number;
  read_username: string;
}

export interface GetPackagePointsResponseDto {
  count: number;
  package_points: PackagePointSimpleDto[];
}

export interface PackagePointFullDto {
  /** @format uuid */
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  display_name: string;
  type: PackageDistributionPointType;
  server: string;
  share_name: string | null;
  port: number;
  read_username: string;
  read_password: string;
}

export interface CreatePackagePointDto {
  display_name: string;
  type: PackageDistributionPointType;
  server: string;
  share_name?: string;
  /**
   * @min 0
   * @max 65535
   */
  port?: number | null;
  read_username?: string;
  read_password?: string;
}

export interface UpdatePackagePointDto {
  display_name?: string;
  type?: PackageDistributionPointType;
  server?: string;
  share_name?: string;
  /**
   * @min 0
   * @max 65535
   */
  port?: number | null;
  read_username?: string;
  read_password?: string;
}

export enum DeviceType {
  Computer = 'computer',
  Mobile = 'mobile'
}

export enum ConfigProfilesSortField {
  DisplayName = 'display_name',
  CompletedProfileInstancesCount = 'completed_profile_instances_count',
  PendingProfileInstancesCount = 'pending_profile_instances_count',
  FailedProfileInstancesCount = 'failed_profile_instances_count',
  Scoped = 'scoped'
}

export interface GetConfigProfilesRequestDto {
  device_type: DeviceType;
  /** @min 1 */
  page?: number;
  /** @min 1 */
  limit?: number;
  sort_field?: ConfigProfilesSortField;
  sort_order?: PaginationSortOrder;
}

export enum ConfigProfileLogField {
  DeviceInformationDeviceName = 'device_information.device_name',
  DeviceSerialNumber = 'device.serial_number',
  CreatedAt = 'created_at',
  Status = 'status'
}

export interface GetConfigProfileLogsRequestDto {
  /** @min 1 */
  page?: number;
  /** @min 1 */
  limit?: number;
  sort_field?: ConfigProfileLogField;
  sort_order?: PaginationSortOrder;
}

export interface ConfigProfileSimpleDto {
  /** @format uuid */
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  profile_id: string;
  display_name: string;
  organization_name: string | null;
  description: string | null;
  scoped: boolean;
  imported: boolean;
  remove_pending: boolean;
  scope_entries?: ScopeEntrySimpleDto[];
  completed_profile_instances_count: number;
  pending_profile_instances_count: number;
  failed_profile_instances_count: number;
}

export interface GetConfigProfilesDto {
  count: number;
  config_profiles: ConfigProfileSimpleDto[];
}

export interface ConfigProfileRestrictions {
  allowActivityContinuation?: boolean;
  allowAddingGameCenterFriends?: boolean;
  allowAirPlayIncomingRequests?: boolean;
  allowAirPrint?: boolean;
  allowAirPrintCredentialsStorage?: boolean;
  allowAirPrintiBeaconDiscovery?: boolean;
  allowAppCellularDataModification?: boolean;
  allowAppClips?: boolean;
  allowAppInstallation?: boolean;
  allowAppRemoval?: boolean;
  allowApplePersonalizedAdvertising?: boolean;
  allowAssistant?: boolean;
  allowAssistantWhileLocked?: boolean;
  allowAutoCorrection?: boolean;
  allowAutoUnlock?: boolean;
  allowAutomaticAppDownloads?: boolean;
  allowBluetoothModification?: boolean;
  allowBookstore?: boolean;
  allowBookstoreErotica?: boolean;
  allowCamera?: boolean;
  allowCellularPlanModification?: boolean;
  allowChat?: boolean;
  allowCloudBackup?: boolean;
  allowCloudDocumentSync?: boolean;
  allowCloudPhotoLibrary?: boolean;
  allowContinuousPathKeyboard?: boolean;
  allowDefinitionLookup?: boolean;
  allowDeviceNameModification?: boolean;
  allowDeviceSleep?: boolean;
  allowDictation?: boolean;
  allowESIMModification?: boolean;
  allowEnablingRestrictions?: boolean;
  allowEnterpriseAppTrust?: boolean;
  allowEnterpriseBookBackup?: boolean;
  allowEnterpriseBookMetadataSync?: boolean;
  allowEraseContentAndSettings?: boolean;
  allowExplicitContent?: boolean;
  allowFilesNetworkDriveAccess?: boolean;
  allowFilesUSBDriveAccess?: boolean;
  allowFindMyDevice?: boolean;
  allowFindMyFriends?: boolean;
  allowFingerprintForUnlock?: boolean;
  allowFingerprintModification?: boolean;
  allowGameCenter?: boolean;
  allowGlobalBackgroundFetchWhenRoaming?: boolean;
  allowInAppPurchases?: boolean;
  allowKeyboardShortcuts?: boolean;
  allowManagedAppsCloudSync?: boolean;
  allowMultiplayerGaming?: boolean;
  allowMusicService?: boolean;
  allowNews?: boolean;
  allowNotificationsModification?: boolean;
  allowOpenFromManagedToUnmanaged?: boolean;
  allowOpenFromUnmanagedToManaged?: boolean;
  allowPairedWatch?: boolean;
  allowPassbookWhileLocked?: boolean;
  allowPasscodeModification?: boolean;
  allowPasswordAutoFill?: boolean;
  allowPasswordProximityRequests?: boolean;
  allowPasswordSharing?: boolean;
  allowPersonalHotspotModification?: boolean;
  allowPhotoStream?: boolean;
  allowPredictiveKeyboard?: boolean;
  allowProximitySetupToNewDevice?: boolean;
  allowRadioService?: boolean;
  allowRemoteAppPairing?: boolean;
  allowRemoteScreenObservation?: boolean;
  allowSafari?: boolean;
  allowScreenShot?: boolean;
  allowSharedStream?: boolean;
  allowSpellCheck?: boolean;
  allowSpotlightInternetResults?: boolean;
  allowSystemAppRemoval?: boolean;
  allowUIAppInstallation?: boolean;
  allowUIConfigurationProfileInstallation?: boolean;
  allowUSBRestrictedMode?: boolean;
  allowUnpairedExternalBootToRecovery?: boolean;
  allowUntrustedTLSPrompt?: boolean;
  allowVPNCreation?: boolean;
  allowVideoConferencing?: boolean;
  allowVoiceDialing?: boolean;
  allowWallpaperModification?: boolean;
  allowiTunes?: boolean;
  blacklistedAppBundleIDs?: string[];
  enforcedSoftwareUpdateDelay?: number;
  forceAirDropUnmanaged?: boolean;
  forceAirPlayIncomingRequestsPairingPassword?: boolean;
  forceAirPlayOutgoingRequestsPairingPassword?: boolean;
  forceAirPrintTrustedTLSRequirement?: boolean;
  forceAssistantProfanityFilter?: boolean;
  forceAuthenticationBeforeAutoFill?: boolean;
  forceAutomaticDateAndTime?: boolean;
  forceClassroomAutomaticallyJoinClasses?: boolean;
  forceClassroomRequestPermissionToLeaveClasses?: boolean;
  forceClassroomUnpromptedAppAndDeviceLock?: boolean;
  forceClassroomUnpromptedScreenObservation?: boolean;
  forceDelayedSoftwareUpdates?: boolean;
  forceEncryptedBackup?: boolean;
  forceITunesStorePasswordEntry?: boolean;
  forceLimitAdTracking?: boolean;
  forceWatchWristDetection?: boolean;
  forceWiFiPowerOn?: boolean;
  forceWiFiWhitelisting?: boolean;
  ratingApps?: number;
  ratingMovies?: number;
  ratingRegion?: string;
  ratingTVShows?: number;
  safariAcceptCookies?: number;
  safariAllowAutoFill?: boolean;
  safariAllowJavaScript?: boolean;
  safariAllowPopups?: boolean;
  safariForceFraudWarning?: boolean;
}

export interface ConfigProfilePasswords {
  allowSimple?: boolean;
  forcePIN?: boolean;
  maxFailedAttempts?: number;
  maxGracePeriod?: number;
  maxInactivity?: number;
  maxPINAgeInDays?: number;
  minComplexChars?: number;
  minLength?: number;
  pinHistory?: number;
  requireAlphanumeric?: boolean;
}

export interface ConfigProfileDomains {
  EmailDomains?: string[];
  SafariPasswordAutoFillDomains?: string[];
  WebDomains?: string[];
}

export interface ConfigProfileHttpProxy {
  ProxyUsername?: string;
  ProxyPassword?: string;
  ProxyServer?: string;
  ProxyServerPort?: number;
  ProxyCaptiveLoginAllowed?: boolean;
  ProxyPACFallbackAllowed?: boolean;
  ProxyPACURL?: string;
  ProxyType?: 'Auto' | 'Manual';
}

export interface ConfigProfileDnsProxy {
  AppBundleIdentifier?: string;
  ProviderBundleIdentifier?: string;
  ProviderBundleConfiguration?: object;
}

export interface ConfigProfileWebContentFilterWhitelistedBookmark {
  Title: string;
  URL: string;
}

export interface ConfigProfileWebContentFilter {
  FilterType?: 'BuiltIn' | 'Plugin';
  AutoFilterEnabled?: boolean;
  FilterBrowsers?: boolean;
  FilterSockets?: boolean;
  Organization?: string;
  Password?: string;
  PluginBundleID?: string;
  ServerAddress?: string;
  UserDefinedName?: string;
  UserName?: string;
  VendorConfig?: object;
  BlacklistedURLs?: string[];
  PermittedURLs?: string[];
  WhitelistedBookmarks?: ConfigProfileWebContentFilterWhitelistedBookmark[];
}

export interface ConfigProfilePayload {
  restrictions?: ConfigProfileRestrictions;
  passwords?: ConfigProfilePasswords;
  domains?: ConfigProfileDomains;
  http_proxy?: ConfigProfileHttpProxy;
  dns_proxy?: ConfigProfileDnsProxy;
  web_content_filter?: ConfigProfileWebContentFilter;
}

export interface ConfigProfileInstanceSimpleDto {
  /** @format uuid */
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  device_id: string;
  in_progress: boolean;
  remove_pending: boolean;
  success: boolean | null;
}

export interface ConfigProfileFullDto {
  /** @format uuid */
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  profile_id: string;
  display_name: string;
  organization_name: string | null;
  description: string | null;
  scoped: boolean;
  imported: boolean;
  remove_pending: boolean;
  scope_entries?: ScopeEntrySimpleDto[];
  completed_profile_instances_count: number;
  pending_profile_instances_count: number;
  failed_profile_instances_count: number;
  payload: ConfigProfilePayload | null;
  imported_payload: string | null;
  instances: ConfigProfileInstanceSimpleDto[];
}

export interface CreateConfigProfileScopeRequestDto {
  type:
    | 'Device'
    | 'StaticGroupDevices'
    | 'SmartGroupDevices'
    | 'DeviceUser'
    | 'StaticGroupDeviceUsers'
    | 'SmartGroupDeviceUsers';
  operation: ScopeEntryOperationType;
  id: string;
}

export interface CreateConfigProfileDto {
  display_name: string;
  device_type: DeviceType;
  description?: string;
  organization_name?: string;
  scoped?: boolean;
  payload: ConfigProfilePayload | null;
  scope_entries?: CreateConfigProfileScopeRequestDto[];
}

export interface ImportConfigProfileDto {
  device_type: DeviceType;
  /** @format binary */
  config: File;
  scoped?: boolean;
  scope_entries?: CreatePolicyScopeRequestDto[];
}

export interface UpdateConfigProfileDto {
  display_name?: string;
  description?: string;
  organization_name?: string;
  scoped?: boolean;
  payload?: object | null;
  scope_entries?: CreateConfigProfileScopeRequestDto[];
}

export interface UpdateByImportConfigProfileDto {
  /** @format binary */
  config: File;
  scoped?: boolean;
  scope_entries?: CreatePolicyScopeRequestDto[];
}

export interface ConfigProfileLogDto {
  /** @format uuid */
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  device: DeviceSimpleDto;
  status: 'in_progress' | 'success' | 'failed';
}

export interface GetConfigProfileLogsResponseDto {
  count: number;
  logs: ConfigProfileLogDto[];
}

export interface AppVersionDto {
  version: string;
}
