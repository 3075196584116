import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import css from './role.module.scss';
import { CreateRoleDto, Permission, RoleSimpleDto, UpdateRoleDto } from '../../../../types/api';
import useRequest from '../../../../hooks/useRequest';
import { Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs.component';
import { createRole, deleteRole, getRole, updateRole } from '../../../../api/roles';
import { getLocalizedErrorString } from '../../../../utils/localize-error';
import {
  IFormPermission,
  resourceNames,
  RoleFormValues
} from './components/role-form/role-form.schema';
import { RoleFormComponent } from './components/role-form/role-form.component';
import { SettingsPaths } from '../settings.const';
import { Paths } from '../../../constants';

export const RoleComponent: FC = () => {
  const { id } = useParams();
  const isNew = id === 'new';

  const { t } = useTranslation();
  const navigate = useNavigate();
  const crudRequest = useRequest<RoleSimpleDto>();
  const roleRef = useRef<RoleSimpleDto>();

  const [isRoleFormShown, setRoleFormShown] = useState(false);
  const [formMode, setFormMode] = useState<'view' | 'create' | 'edit'>(isNew ? 'create' : 'view');
  const [roleFormValues, setRoleFormValues] = useState<RoleFormValues>();

  const roleToForm = (input: RoleSimpleDto): RoleFormValues => {
    const formPermissions: IFormPermission[] = [];

    for (const resource of resourceNames) {
      formPermissions.push({
        resource,
        read: input.permissions
          ? input.permissions.includes(Permission[`Read${resource}` as keyof typeof Permission])
          : false,
        edit: input.permissions
          ? input.permissions.includes(Permission[`Edit${resource}` as keyof typeof Permission])
          : false
      });
    }

    return {
      title: input.title,
      permissions: formPermissions
    };
  };

  const formPermissionsToDto = (formPermissions: IFormPermission[]): Permission[] => {
    const permissions: Permission[] = [];

    for (const formPermission of formPermissions) {
      if (formPermission.read) {
        permissions.push(Permission[`Read${formPermission.resource}` as keyof typeof Permission]);
      }
      if (formPermission.edit) {
        permissions.push(Permission[`Edit${formPermission.resource}` as keyof typeof Permission]);
      }
    }
    return permissions;
  };

  const handleRoleCreate = async (values: RoleFormValues) => {
    if (crudRequest.loading) return;

    const request: CreateRoleDto = {
      title: values.title,
      ...(values.permissions && { permissions: formPermissionsToDto(values.permissions) })
    };

    try {
      const response = await crudRequest.send(createRole(request));
      setRoleFormValues(values);
      setFormMode('view');
      navigate(`/${Paths.SETTINGS}${SettingsPaths.ROLE.replace(':id', response.id)}`);
      toast.success(
        t('settings.tiles.roles.page.role_page.successfully_created', {
          rolename: values.title
        }),
        {
          autoClose: 5000
        }
      );
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toast.error(localizedErrorString, {
        autoClose: 5000
      });
    }
  };

  const handleRoleEdit = () => setFormMode('edit');
  const handleCancel = () => setFormMode('view');

  const handleRoleSave = async (values: Partial<RoleFormValues>) => {
    if (crudRequest.loading || !roleRef.current || !values) return;

    const request: UpdateRoleDto = {
      ...(values.title && { title: values.title }),
      ...(values.permissions && { permissions: formPermissionsToDto(values.permissions) })
    };

    try {
      const response = await crudRequest.send(updateRole(roleRef.current?.id, request));
      roleRef.current = response;
      setRoleFormValues(roleToForm(response));
      setFormMode('view');
      navigate(`/${Paths.SETTINGS}${SettingsPaths.ROLE.replace(':id', response.id)}`);
      toast.success(
        t('settings.tiles.roles.page.role_page.successfully_updated', {
          rolename: response.title
        }),
        {
          autoClose: 5000
        }
      );
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toast.error(localizedErrorString, {
        autoClose: 5000
      });
    }
  };

  const handleRoleDelete = async () => {
    if (crudRequest.loading || !roleRef.current) return;
    try {
      const response = await crudRequest.send(deleteRole(roleRef.current?.id));
      navigate(`/${Paths.SETTINGS}${SettingsPaths.ROLES_LIST}`);
      toast.success(
        t('settings.tiles.roles.page.role_page.successfully_deleted', {
          rolename: response.title
        }),
        {
          autoClose: 5000
        }
      );
    } catch (e) {
      toast.error(getLocalizedErrorString(e as Error), {
        autoClose: 5000
      });
    }
  };

  useEffect(() => {
    const init = async () => {
      if (!id) return;
      try {
        if (!isNew) {
          const response = await getRole(id);
          roleRef.current = response;
          setRoleFormValues(roleToForm(response));
        }
        // const { roles: roleDtos } = await getRoles();
        // setRoles(roleDtos.map((roleDto) => ({ id: roleDto.id, title: roleDto.title })));
        setRoleFormShown(true);
      } catch (error) {
        const localizedErrorString = getLocalizedErrorString(error as Error);
        toast.error(localizedErrorString, {
          autoClose: 5000
        });
      }
    };
    void init();
  }, [id]);

  return (
    <div className={css.Root}>
      <Breadcrumbs title={roleRef.current?.title} />
      {isRoleFormShown && (
        <RoleFormComponent
          mode={formMode}
          values={roleFormValues}
          onCreate={handleRoleCreate}
          onEdit={handleRoleEdit}
          onSave={handleRoleSave}
          onDelete={handleRoleDelete}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};
