import { useEffect, useState } from 'react';
import { createContainer } from 'react-tracked';

import { useAuthContext } from './auth.context';
import { Permission } from '../../types/api';

type PermissionContextType = {
  loading: boolean;
  isAllowedTo: (...permissions: Permission[]) => boolean;
};

const defaultBehaviour: PermissionContextType = {
  isAllowedTo: () => false,
  loading: true
};

const useInitialState = () => useState(defaultBehaviour);
const container = createContainer(useInitialState);
const PermissionProvider = container.Provider;

const usePermission = (): PermissionContextType => {
  const [state, setState] = container.useTracked();
  const { authState } = useAuthContext();

  useEffect(() => {
    if (!authState.role?.permissions) return;
    const permissions = authState.role?.permissions;
    const isAllowedTo = (...requiredPermissions: Permission[]) =>
      permissions
        ? requiredPermissions.some((permission) => permissions.includes(permission))
        : false;
    setState({ isAllowedTo, loading: false });
  }, [authState]);

  return { isAllowedTo: state.isAllowedTo, loading: state.loading };
};

export { PermissionProvider, usePermission };
