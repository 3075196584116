import { FC, FormEvent, useEffect, useState } from 'react';
import { TextInput, TextInputType } from '../../../components/text-input/text-input.component';
import css from './settings-filevault-escrow-certificate.module.scss';
import { checkSettings, updateSettings } from '../../../../api/settings';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/button/button.component';
import { FileInput } from '../../../components/file-input/file-input.component';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FilevaultEscrowCertificateForm,
  filevaultEscrowCertificateFormSchema
} from './settings-filevault-escrow-certificate.schema';
import { Permission, UpdateSettingsDto } from '../../../../types/api';
import useRequest from '../../../../hooks/useRequest';
import { Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs.component';
import { getLocalizedErrorString } from '../../../../utils/localize-error';
import { usePermission } from '../../../contexts/permission.context';

const translation = (path: string) => `settings.tiles.filevault_escrow_certificate.${path}`;

export const SettingsFilevaultEscrowCertificate: FC = () => {
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const updateRequest = useRequest();
  const [serverError, setServerError] = useState<string>();
  const [isConfigured, setIsConfigured] = useState<boolean>();
  const [isEdit, setIsEdit] = useState(false);
  const { register, formState, trigger, getValues, reset } =
    useForm<FilevaultEscrowCertificateForm>({
      mode: 'onChange',
      resolver: yupResolver(filevaultEscrowCertificateFormSchema),
      defaultValues: filevaultEscrowCertificateFormSchema.getDefault()
    });

  const handleEdit = () => setIsEdit(true);
  const handleCancel = () => {
    if (updateRequest.loading) return;
    setIsEdit(false);
    setServerError('');
    reset();
  };

  const handleFormSubmit = async (event?: FormEvent) => {
    if (updateRequest.loading) return;
    event?.preventDefault();
    const isValid = await trigger();
    if (!isValid) return;
    const values = getValues();
    const arrayBuffer = await values.certificate.item(0)?.arrayBuffer();
    if (!arrayBuffer) return;
    const certBase64 = btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)));
    try {
      const request: Partial<UpdateSettingsDto> = {
        filevault_escrow_cert_key: certBase64,
        filevault_escrow_cert_key_secret: values.secret
      };
      await updateRequest.send(updateSettings(request));
      setIsEdit(false);
      setIsConfigured(true);
      setServerError('');
    } catch (error: unknown) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      setServerError(String(localizedErrorString));
    }
  };

  useEffect(() => {
    const init = async () => {
      const settings = await checkSettings([
        'filevault_escrow_cert_key',
        'filevault_escrow_cert_key_secret'
      ]);
      const isCertificateConfigured = Boolean(
        settings.filevault_escrow_cert_key && settings.filevault_escrow_cert_key_secret
      );
      setIsConfigured(isCertificateConfigured);
    };
    void init();
  }, []);

  return (
    <div>
      <Breadcrumbs />
      <div className={css.Header}>
        <h5>{t(translation('name'))}</h5>
      </div>
      <div className={css.Content}>
        <div className={css.Status}>
          <span>{t(translation('page.status'))}: </span>
          {isConfigured === true && <span>{t(translation('page.status_configured'))}</span>}
          {isConfigured === false && <span>{t(translation('page.status_not_configured'))}</span>}
        </div>
        {isEdit && (
          <form onSubmit={handleFormSubmit}>
            <FileInput
              label={t(translation('page.certificate_file'))}
              accept=".key,.pem"
              errorText={formState.errors.certificate?.message}
              register={register('certificate')}
            />
            <TextInput
              label={t(translation('page.certificate_secret'))}
              required
              type={TextInputType.PASSWORD}
              register={register('secret')}
              errorText={formState.errors.secret?.message}
            />
          </form>
        )}
        <div className={css.ActionBtnGroup}>
          {serverError && <span className={css.ServerError}>{serverError}</span>}
          {!isEdit && (
            <Button
              isDisabled={!isAllowedTo(Permission.EditAdministration)}
              className={css.ActionBtn}
              theme="primary"
              onClick={handleEdit}
            >
              {t(translation('page.edit_btn'))}
            </Button>
          )}
          {isEdit && (
            <>
              <Button className={css.ActionBtn} onClick={handleCancel}>
                {t(translation('page.cancel_btn'))}
              </Button>
              <Button
                className={css.ActionBtn}
                type="submit"
                theme="primary"
                isLoading={updateRequest.loading}
                onClick={handleFormSubmit}
              >
                {t(translation('page.save_btn'))}
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
