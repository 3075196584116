import { FC, FormEvent, useEffect, useState } from 'react';
import { TabItem, TabNavbar } from '../../../../components/tab/tab-navbar/tab-navbar.component';
import css from './static-group-form.module.scss';
import { StaticGroupGeneralForm, staticGroupGeneralFormSchema } from './static-group-form.schema';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Button } from '../../../../components/button/button.component';
import { Permission, StaticGroupDevicesFullDto } from '../../../../../types/api';
import { Modal } from '../../../../components/modal/modal.component';
import { StaticGroupFormModes } from '../../static-group-devices.component';
import { DeviceTable } from '../device-table/device-table.component';
import { useTranslation } from 'react-i18next';
import { usePermission } from '../../../../contexts/permission.context';
import { TextInput } from '../../../../components/text-input/text-input.component';

export enum StaticGroupPageTabs {
  COMPUTER_GROUP = 'Computer Group',
  ASSIGNMENTS = 'Assignments'
}

interface IProps {
  mode: StaticGroupFormModes;
  values?: StaticGroupGeneralForm;
  group: StaticGroupDevicesFullDto | undefined;
  setUpdateMode: (state: boolean) => void;
  onCreate?: (values: StaticGroupGeneralForm, devices: string[]) => void;
  onUpdate?: (values: StaticGroupGeneralForm, devices: string[]) => void;
  onDelete?: () => void;
  onCancel?: () => void;
}

export const StaticGroupForm: FC<IProps> = (props: IProps) => {
  const { onCreate, onDelete, onUpdate, setUpdateMode, onCancel, group, mode } = props;

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<string>(StaticGroupPageTabs.COMPUTER_GROUP);
  const [initialSelectedDevices, setInitialSelectedDevices] = useState<string[]>([]);
  const [selectedDevices, setSelectedDevices] = useState<string[]>([]);

  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const staticGroupForm = useForm<StaticGroupGeneralForm>({
    mode: 'onChange',
    resolver: yupResolver(staticGroupGeneralFormSchema),
    defaultValues: staticGroupGeneralFormSchema.getDefault()
  });

  useEffect(() => {
    if (group) {
      staticGroupForm.setValue('display_name', group.title);
      if (mode === StaticGroupFormModes.VIEW) {
        setInitialSelectedDevices(group.devices.map((i) => i.id));
        setSelectedDevices(group.devices.map((i) => i.id));
      }
    }
  }, [group, mode]);

  const handleUpdateInitialDevices = (devices: string[]) => {
    setInitialSelectedDevices(devices);
  };

  const handleUpdateSelectedDevices = (devices: string[]) => {
    setSelectedDevices(devices);
  };

  const handleChangeTab = (tab: string) => {
    setInitialSelectedDevices(selectedDevices);
    setActiveTab(tab);
  };

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
  };

  const handleCreate = async () => {
    await staticGroupForm.trigger();
    const errorKeys = Object.keys(staticGroupForm.formState.errors);
    if (errorKeys.length > 0) return;
    onCreate?.(staticGroupForm.getValues(), selectedDevices);
  };

  const handleSendUpdate = async () => {
    await staticGroupForm.trigger();
    const errorKeys = Object.keys(staticGroupForm.formState.errors);
    if (errorKeys.length > 0) return;
    onUpdate?.(staticGroupForm.getValues(), selectedDevices);
  };

  const handleDeleteModalOpen = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteModalSubmit = () => {
    onDelete?.();
    setIsDeleteModalOpen(false);
  };

  const handleClickCancel = async () => {
    onCancel?.();
  };

  const tabs: TabItem[] = [
    {
      key: StaticGroupPageTabs.COMPUTER_GROUP,
      title: t('static_groups.page.computer_group_tab.name')
    },
    { key: StaticGroupPageTabs.ASSIGNMENTS, title: t('static_groups.page.assignments_tab.name') }
  ];

  return (
    <form className={css.Root} onSubmit={handleFormSubmit}>
      <fieldset className={css.Fieldset} disabled={!isAllowedTo(Permission.EditGroups)}>
        <TabNavbar tabs={tabs} activeTab={activeTab} onChangeTab={handleChangeTab} />
        {activeTab === StaticGroupPageTabs.COMPUTER_GROUP && (
          <div className={css.GroupTab}>
            <TextInput
              className={css.GroupTabInput}
              label={t('static_groups.page.computer_group_tab.display_name')}
              register={staticGroupForm.register('display_name')}
              errorText={staticGroupForm.formState.errors.display_name?.message}
              disabled={mode === 'view'}
              required
            />
          </div>
        )}
        {activeTab === StaticGroupPageTabs.ASSIGNMENTS && (
          <DeviceTable
            mode={mode}
            selectedDevices={selectedDevices}
            updateSelectedDevices={handleUpdateSelectedDevices}
            initialSelectedDevices={initialSelectedDevices}
            updateInitialSelectedDevices={handleUpdateInitialDevices}
          />
        )}
        <Modal
          title={t('static_groups.page.modal_delete.title')}
          submitButtonName={t('static_groups.page.modal_delete.delete_btn')}
          isOpen={isDeleteModalOpen}
          onRequestClose={handleDeleteModalClose}
          onSubmit={handleDeleteModalSubmit}
        >
          <p>{t('static_groups.page.modal_delete.message', { title: group?.title })}</p>
        </Modal>
        <div className={css.StaticGroupActions}>
          {mode === 'new' && (
            <Button className={css.Button} theme="primary" type="submit" onClick={handleCreate}>
              {t('static_groups.page.create_btn')}
            </Button>
          )}
          {mode === 'view' && (
            <Button
              className={css.Button}
              theme="primary"
              type="submit"
              onClick={() => setUpdateMode(true)}
            >
              {t('static_groups.page.edit_btn')}
            </Button>
          )}
          {mode === 'update' && (
            <>
              <Button
                className={css.Button}
                theme="primary"
                type="submit"
                onClick={handleSendUpdate}
              >
                {t('static_groups.page.update_btn')}
              </Button>
              <Button className={css.Button} theme="danger" onClick={handleClickCancel}>
                {t('smart_groups.page.cancel_btn')}
              </Button>
            </>
          )}
          {mode === ('view' || 'update') && (
            <Button className={css.Button} theme="danger" onClick={handleDeleteModalOpen}>
              {t('static_groups.page.delete_btn')}
            </Button>
          )}
        </div>
      </fieldset>
    </form>
  );
};
