import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import css from './settings-sections.module.scss';
import { TabItem, TabNavbar } from '../../../components/tab/tab-navbar/tab-navbar.component';
import { Paths } from '../../../constants';
import { SettingsPaths } from '../settings.const';
import { Permission } from '../../../../types/api';
import { usePermission } from '../../../contexts/permission.context';

enum SettingsPageTab {
  ALL = 'All',
  SYSTEM = 'System'
}

type ISettingItem = {
  name: string;
  path: string;
  description: string;
  requiredPermissions?: Permission[];
};

export const SettingsSections: FC = () => {
  const [activeTab, setActiveTab] = useState<string>(SettingsPageTab.ALL);
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const handleChangeTab = (tabName: string) => {
    setActiveTab(tabName);
  };

  const tabs: TabItem[] = [
    { key: SettingsPageTab.ALL, title: t('settings.bar.items.all') },
    { key: SettingsPageTab.SYSTEM, title: t('settings.bar.items.system') }
  ];

  const settingsItems: ISettingItem[] = [
    {
      name: t('settings.tiles.organization_information.name'),
      path: `${Paths.SETTINGS}${SettingsPaths.ORGANIZATION}`,
      description: t('settings.tiles.organization_information.description'),
      requiredPermissions: [Permission.ReadAdministration, Permission.EditAdministration]
    },
    {
      name: t('settings.tiles.scripts.name'),
      path: `${Paths.SETTINGS}${SettingsPaths.SCRIPT_LIST}`,
      description: t('settings.tiles.scripts.description'),
      requiredPermissions: [Permission.ReadScripts, Permission.EditScripts]
    },
    {
      name: t('settings.tiles.scripts_config.name'),
      path: `${Paths.SETTINGS}${SettingsPaths.SCRIPTS_CONFIG}`,
      description: t('settings.tiles.scripts_config.description'),
      requiredPermissions: [Permission.ReadScripts, Permission.EditScripts]
    },
    {
      name: t('settings.tiles.policies_config.name'),
      path: `${Paths.SETTINGS}${SettingsPaths.POLICIES_CONFIG}`,
      description: t('settings.tiles.policies_config.description'),
      requiredPermissions: [Permission.ReadScripts]
    },
    {
      name: t('settings.tiles.packages.name'),
      path: `${Paths.SETTINGS}${SettingsPaths.PACKAGE_LIST}`,
      description: t('settings.tiles.packages.description'),
      requiredPermissions: [Permission.ReadPackages, Permission.EditPackages]
    },
    {
      name: t('settings.tiles.package_distribution_points.name'),
      path: `${Paths.SETTINGS}${SettingsPaths.PACKAGE_POINT_LIST}`,
      description: t('settings.tiles.package_distribution_points.description'),
      requiredPermissions: [Permission.ReadPackages, Permission.EditPackages]
    },
    {
      name: t('settings.tiles.filevault_escrow_certificate.name'),
      path: `${Paths.SETTINGS}${SettingsPaths.FILEVAULT_ESCROW_CERTIFICATE}`,
      description: t('settings.tiles.filevault_escrow_certificate.description'),
      requiredPermissions: [Permission.ReadAdministration, Permission.EditAdministration]
    },
    {
      name: t('settings.tiles.license.name'),
      path: `${Paths.SETTINGS}${SettingsPaths.LICENSE}`,
      description: t('settings.tiles.license.description'),
      requiredPermissions: [Permission.ReadAdministration, Permission.EditAdministration]
    },
    {
      name: t('settings.tiles.upload_certificate.name'),
      path: `${Paths.SETTINGS}${SettingsPaths.UPLOAD_CERTIFICATE}`,
      description: t('settings.tiles.upload_certificate.description'),
      requiredPermissions: [Permission.ReadAdministration, Permission.EditAdministration]
    },
    {
      name: t('settings.tiles.users.name'),
      path: `${Paths.SETTINGS}${SettingsPaths.USER_LIST}`,
      description: t('settings.tiles.users.description'),
      requiredPermissions: [Permission.ReadAdministration, Permission.EditAdministration]
    },
    {
      name: t('settings.tiles.roles.name'),
      path: `${Paths.SETTINGS}${SettingsPaths.ROLES_LIST}`,
      description: t('settings.tiles.roles.description'),
      requiredPermissions: [Permission.ReadAdministration, Permission.EditAdministration]
    },
    {
      name: t('settings.tiles.extension_attributes.name'),
      path: `${Paths.SETTINGS}${SettingsPaths.EXTENSION_ATTRIBUTES_LIST}`,
      description: t('settings.tiles.extension_attributes.description'),
      requiredPermissions: [Permission.ReadDevices, Permission.EditDevices]
    },
    {
      name: t('settings.tiles.ldap.name'),
      path: `${Paths.SETTINGS}${SettingsPaths.LDAP}`,
      description: t('settings.tiles.ldap.description'),
      requiredPermissions: [Permission.ReadAdministration, Permission.EditAdministration]
    }
  ];

  const filterByPermission = (item: ISettingItem) =>
    item.requiredPermissions ? isAllowedTo(...item.requiredPermissions) : false;

  return (
    <div>
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h5 className={css.SettingsTitle}>{t('settings.name')}</h5>
          </div>
        </div>
      </div>
      <TabNavbar
        className={css.SettingsNavbar}
        tabs={tabs}
        activeTab={activeTab}
        onChangeTab={handleChangeTab}
      />
      {activeTab === SettingsPageTab.ALL && (
        <div className={css.Tab}>
          {settingsItems.filter(filterByPermission).map((settingItem, i) => (
            <div className={css.SettingsCell} key={i}>
              <Link to={`/${settingItem.path}`} className={`card ${css.SettingsCard}`}>
                <div className={cn('card-header', css.CardHeader)}>
                  <h3 className="card-title">{settingItem.name}</h3>
                </div>
                <div className="card-body">{settingItem.description}</div>
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
