import css from './policy.module.scss';
import { FC, useEffect, useRef, useState } from 'react';
import { createPolicy, deletePolicy, getPolicy, updatePolicy } from '../../../api/policies';
import {
  PolicyFullDto,
  PolicySimpleDto,
  UpdatePolicyRequestDto,
  UpdatePolicyRerun
} from '../../../types/api';
import { useNavigate, useParams } from 'react-router-dom';
import { PolicyForm, PolicyFormValues } from './components/policy-form/policy-form.component';
import { Modal } from '../../components/modal/modal.component';
import useRequest from '../../../hooks/useRequest';
import { formToPolicy, policyToForm } from './policy.utils';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs.component';
import { getLocalizedErrorString } from '../../../utils/localize-error';
import { toast } from 'react-toastify';

export const Policy: FC = () => {
  const { id } = useParams();
  const policyRef = useRef<PolicyFullDto>();
  const navigate = useNavigate();
  const crudPolicyRequest = useRequest<PolicySimpleDto>();

  const [isPolicyFormShown, setIsPolicyFormShown] = useState(false);
  const [policyFormValues, setPolicyFormValues] = useState<PolicyFormValues>();
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const { t } = useTranslation();

  const handlePolicyCreateCancel = () => navigate('/computers/policies');
  const handlePolicyCreate = async (values: PolicyFormValues) => {
    if (crudPolicyRequest.loading) return;

    const request = formToPolicy(values);
    try {
      const response = await crudPolicyRequest.send(createPolicy(request));
      setPolicyFormValues(values);
      navigate(`/computers/policies/${response.id}`);
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toast.error(localizedErrorString, { autoClose: 5000 });
    }
  };

  const handleEditStart = () => setIsEditMode(true);
  const handleEditCancel = () => {
    if (crudPolicyRequest.loading) return;
    if (policyRef.current) {
      setPolicyFormValues(policyToForm(policyRef.current));
    }
    setIsEditMode(false);
  };
  const handleEditRequest = async (values: PolicyFormValues) => {
    if (!id || crudPolicyRequest.loading) return;

    const request: UpdatePolicyRequestDto = {
      ...formToPolicy(values),
      rerun: UpdatePolicyRerun.All
    };
    try {
      const response = await updatePolicy(id, request);
      policyRef.current = response;
      setPolicyFormValues(policyToForm(response));
      navigate(`/computers/policies/${response.id}`);
      setIsEditMode(false);
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toast.error(localizedErrorString, { autoClose: 5000 });
    }
  };

  const handlePolicyDelete = () => setIsDeleteModalShown(true);
  const handleDeleteModalClose = () => {
    if (crudPolicyRequest.loading) return;
    setIsDeleteModalShown(false);
  };
  const handleDeleteModalSubmit = async () => {
    if (crudPolicyRequest.loading || !policyRef.current) return;
    try {
      await crudPolicyRequest.send(deletePolicy(policyRef.current.id));
      navigate('/computers/policies');
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toast.error(localizedErrorString, { autoClose: 5000 });
    }
    handleDeleteModalClose();
  };

  const init = async () => {
    if (!id) return;
    if (id !== 'new') {
      const response = await getPolicy(id);
      policyRef.current = response;
      setPolicyFormValues(policyToForm(response));
      console.log('new values');
    }
    setIsPolicyFormShown(true);
  };

  useEffect(() => {
    void init();
  }, [id]);

  const policyFormMode = () => {
    if (id === 'new') return 'create';
    if (isEditMode) return 'edit';
    return 'view';
  };
  return (
    <div className={css.Root}>
      <Breadcrumbs title={policyRef.current?.title} />
      {isPolicyFormShown && (
        <PolicyForm
          mode={policyFormMode()}
          values={policyFormValues}
          policyId={id}
          onCreate={handlePolicyCreate}
          onCreateCancel={handlePolicyCreateCancel}
          onDelete={handlePolicyDelete}
          onEditStart={handleEditStart}
          onEditCancel={handleEditCancel}
          onEditRequest={handleEditRequest}
        />
      )}
      <Modal
        title={t('policies.policy.modal_title_delete')}
        submitButtonName={t('policies.policy.modal_button_delete')}
        isOpen={isDeleteModalShown}
        onRequestClose={handleDeleteModalClose}
        onSubmit={handleDeleteModalSubmit}
        isLoading={crudPolicyRequest.loading}
      >
        <p>{t('policies.policy.modal_message', { policy_title: policyRef.current?.title })}</p>
      </Modal>
    </div>
  );
};
