import { FC, FormEvent, useEffect, useState } from 'react';
import { TabItem, TabNavbar } from '../../../../components/tab/tab-navbar/tab-navbar.component';
import { GroupTab } from '../group-tab/group-tab.component';
import css from './smart-group-device-users-form.module.scss';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Button } from '../../../../components/button/button.component';
import { CriteriaTab } from '../criteria-tab/criteria-tab.component';
import { BlankPage } from '../../../../components/blank-page/blank-page.component';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../components/modal/modal.component';
import { usePermission } from '../../../../contexts/permission.context';
import { Permission } from '../../../../../types/api';
import {
  smartGroupDeviceUsersFormSchema,
  SmartGroupDeviceUsersFormValues
} from './smart-group-device-users-form.schema';
import cn from 'classnames';

export enum SmartGroupDeviceUsersPageTabs {
  COMPUTER_GROUP = 'Computer Group',
  CRITERIA = 'Criteria',
  REPORTS = 'Reports'
}

interface IProps {
  mode: 'view' | 'create' | 'edit' | 'update';
  values?: SmartGroupDeviceUsersFormValues;
  onCreate?: (values: SmartGroupDeviceUsersFormValues) => void;
  onDelete?: () => void;
  onEdit?: () => void;
  onSave?: (values: SmartGroupDeviceUsersFormValues) => void;
  onCancel?: () => void;
}

export const SmartGroupDevicesForm: FC<IProps> = (props: IProps) => {
  const { values, onCreate, onDelete, onEdit, onSave, onCancel, mode = 'view' } = props;

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<string>(SmartGroupDeviceUsersPageTabs.COMPUTER_GROUP);
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const smartGroupForm = useForm<SmartGroupDeviceUsersFormValues>({
    mode: 'onChange',
    resolver: yupResolver(smartGroupDeviceUsersFormSchema),
    defaultValues: values || smartGroupDeviceUsersFormSchema.getDefault()
  });

  const handleChangeTab = (tab: string) => {
    setActiveTab(tab);
  };

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
  };

  const handleCreate = async () => {
    await smartGroupForm.trigger();
    const errorKeys = Object.keys(smartGroupForm.formState.errors);
    if (errorKeys.length > 0) return;
    const formValues = smartGroupForm.getValues();
    const processedValues: SmartGroupDeviceUsersFormValues = {
      ...formValues,
      criteria: formValues.criteria?.map((i) => ({
        ...i,
        start_bracket: String(i.start_bracket) === 'true',
        end_bracket: String(i.end_bracket) === 'true'
      }))
    };
    onCreate?.(processedValues);
  };

  const handleClickEdit = () => onEdit?.();

  const handleClickCancel = async () => {
    await smartGroupForm.reset();
    onCancel?.();
  };

  const handleClickSave = async () => {
    const isValid = await smartGroupForm.trigger();
    if (!isValid) return;

    const formValues = smartGroupForm.getValues();
    const processedValues: SmartGroupDeviceUsersFormValues = {
      ...formValues,
      criteria: formValues.criteria?.map((i) => ({
        ...i,
        start_bracket: String(i.start_bracket) === 'true',
        end_bracket: String(i.end_bracket) === 'true'
      }))
    };
    onSave?.(processedValues);
  };

  const handleDeleteModalOpen = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteModalSubmit = () => {
    onDelete?.();
    setIsDeleteModalOpen(false);
  };

  useEffect(() => {
    if (values?.group.display_name) {
      smartGroupForm.setValue('group.display_name', values.group.display_name);
    }
  }, [values]);

  const tabs: TabItem[] = [
    {
      key: SmartGroupDeviceUsersPageTabs.COMPUTER_GROUP,
      title: t('smart_groups.page.device_users_group_tab.name')
    },
    {
      key: SmartGroupDeviceUsersPageTabs.CRITERIA,
      title: t('smart_groups.page.criteria_tab.name')
    },
    { key: SmartGroupDeviceUsersPageTabs.REPORTS, title: t('smart_groups.page.reports_tab.name') }
  ];

  return (
    <form className={css.Root} onSubmit={handleFormSubmit}>
      <fieldset className={css.Fieldset} disabled={!isAllowedTo(Permission.EditGroups)}>
        <TabNavbar tabs={tabs} activeTab={activeTab} onChangeTab={handleChangeTab} />
        {activeTab === SmartGroupDeviceUsersPageTabs.COMPUTER_GROUP && (
          <GroupTab disabled={mode === 'view'} form={smartGroupForm} />
        )}
        <CriteriaTab
          className={cn(activeTab !== SmartGroupDeviceUsersPageTabs.CRITERIA && css.Hidden)}
          disabled={mode === 'view'}
          form={smartGroupForm}
        />
        {activeTab === SmartGroupDeviceUsersPageTabs.REPORTS && (
          <BlankPage text={t('smart_groups.page.reports_tab.no_content')} />
        )}
        <Modal
          title={t('smart_groups.page.modal_delete.title')}
          submitButtonName={t('smart_groups.page.modal_delete.delete_btn')}
          isOpen={isDeleteModalOpen}
          onRequestClose={handleDeleteModalClose}
          onSubmit={handleDeleteModalSubmit}
        >
          <p>
            {t('static_groups.page.modal_delete.message', { title: values?.group.display_name })}
          </p>
        </Modal>
        <div className={css.SmartGroupActions}>
          {mode === 'create' && (
            <Button theme="primary" type="submit" onClick={handleCreate}>
              {t('smart_groups.page.create_btn')}
            </Button>
          )}
          {mode === 'view' && (
            <>
              <Button className={css.SmartGroupActionBtn} theme="primary" onClick={handleClickEdit}>
                {t('smart_groups.page.edit_btn')}
              </Button>
              <Button
                className={css.SmartGroupActionBtn}
                theme="danger"
                onClick={handleDeleteModalOpen}
              >
                {t('smart_groups.page.delete_btn')}
              </Button>
            </>
          )}
          {mode === 'edit' && (
            <>
              <Button className={css.SmartGroupActionBtn} theme="primary" onClick={handleClickSave}>
                {t('smart_groups.page.save_btn')}
              </Button>
              <Button
                className={css.SmartGroupActionBtn}
                theme="danger"
                onClick={handleClickCancel}
              >
                {t('smart_groups.page.cancel_btn')}
              </Button>
            </>
          )}
        </div>
      </fieldset>
    </form>
  );
};
