import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { ISODateString } from '../types/common';
import i18next from '../i18n/i18n';

const SERVER_URL = `${process.env.REACT_APP_SERVER_URL || ''}/`;

axios.defaults.baseURL = SERVER_URL;
axios.defaults.timeout = 120000;

export type AxiosRequestConfigExtended = AxiosRequestConfig & {
  public?: boolean;
  prefix?: string;
};

export interface IServerError {
  statusCode: number;
  message: string;
  error: string;
  timestamp: ISODateString;
  path: string;
}

class ServerError extends Error {
  private data: IServerError;

  constructor(message: string, data: IServerError) {
    super(message);
    this.data = data;
  }

  toString() {
    return `${i18next.t('common.error')}: ${this.message}`;
  }
}

export async function createRequest<T>(config: AxiosRequestConfigExtended): Promise<T> {
  try {
    const prefix = config.prefix || 'api/v1';
    const url = `${prefix}/${config.url}`;
    const { data } = await axios({ ...config, url });
    return data;
  } catch (e) {
    const errorResponse = (e as AxiosError).response?.data as IServerError;
    const errorObject = errorResponse || { message: 'Unknown error' };
    throw new ServerError(errorObject.message, errorObject);
  }
}
