export const DeviceSections = {
  COMPUTERS: '/computers',
  MOBILE: '/mobile'
};

export const Paths = {
  SIGN_IN: '/auth',
  HOME: '/',
  POLICY_LIST: 'policies',
  POLICY: 'policies/:id',
  CONFIGURATION_PROFILE_LIST: 'configuration-profiles',
  CONFIGURATION_PROFILE: 'configuration-profiles/:id',
  INVENTORY: 'inventory',
  SCRIPT_LIST: 'scripts',
  SCRIPT: 'scripts/:id',
  SMART_GROUP_DEVICES_LIST: 'smart-groups',
  SMART_GROUP_DEVICES: 'smart-groups/:id',
  SMART_GROUP_DEVICE_USERS_LIST: 'device-users/smart-groups',
  SMART_GROUP_DEVICE_USERS: 'device-users/smart-groups/:id',
  SETTINGS: 'settings',
  STATIC_GROUP_DEVICES_LIST: 'static-groups',
  STATIC_GROUP_DEVICES: 'static-groups/:id',
  STATIC_GROUP_DEVICE_USERS_LIST: 'device-users/static-groups',
  STATIC_GROUP_DEVICE_USERS: 'device-users/static-groups/:id',
  DEVICE_USER_LIST: 'device-users',
  DEVICE_USER: 'device-users/:id'
};
