import css from './tab-navbar-item.module.scss';
import { FC, FormEvent } from 'react';
import cn from 'classnames';
import { WithClassname } from '../../../../../types/common';

interface IProps {
  title: string;
  active: boolean;
  onClick: () => void;
  disabled?: boolean;
}

export const TabNavItem: FC<IProps & WithClassname> = (props) => {
  const { className, title, active, onClick, disabled = false } = props;

  const handleOnClick = () => {
    if (disabled) {
      return;
    }
    onClick?.();
  };

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
  };

  return (
    <li
      className={cn(css.Root, className, active && css.Active, disabled && css.disabled)}
      onClick={handleOnClick}
    >
      <div onSubmit={handleFormSubmit}>
        <fieldset disabled={disabled}>{title}</fieldset>
      </div>
    </li>
  );
};
